import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")!
  const _component_list = _resolveComponent("list")!
  const _component_list_collapsible_block = _resolveComponent("list-collapsible-block")!

  return (_openBlock(), _createBlock(_component_list_collapsible_block, {
    label: "Grunnforurensning",
    count: _ctx.soilContaminations.length
  }, {
    default: _withCtx(() => [
      _createVNode(_component_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.soilContaminations, (soilContamination) => {
            return (_openBlock(), _createBlock(_component_list_item, {
              title: soilContamination.localityName,
              subtitle: soilContamination.degreeOfImpactType.text,
              link: `${_ctx.projectStore.getProjectRoute}/${_ctx.cadastre.key}/grunnforurensning/${soilContamination.key}`,
              key: soilContamination.key
            }, null, 8, ["title", "subtitle", "link"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["count"]))
}