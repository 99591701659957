import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "details-panel-list-item" }
const _hoisted_2 = { class: "details-panel-list-item-content" }
const _hoisted_3 = { class: "details-panel-list-item-subtitle" }

import { computed } from 'vue';
import CaretRightIcon from '@ambita/design-system-icons/dist/icons/regular/caret-right.svg';
import TextUtil from '@/utilities/TextUtil';
import { RouterLink } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ListItem',
  props: {
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: false,
  },
  link: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const prettyLabel = computed(() => {
  return new TextUtil(props.title).uCaseFirst();
});

const prettySubtitle = computed(() => {
  return new TextUtil(props.subtitle).uCaseFirst();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_unref(RouterLink), {
      to: __props.link,
      class: "details-panel-list-item-link"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(prettyLabel.value) + " ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(prettySubtitle.value), 1)
        ]),
        _createVNode(_unref(CaretRightIcon), { class: "details-panel-list-item-icon" })
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}
}

})