import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  src: "/img/propfinder_logo_light.svg",
  alt: "PropFinder"
}
const _hoisted_2 = {
  key: 1,
  src: "/img/propfinder_logo_dark.svg",
  alt: "PropFinder"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_picker = _resolveComponent("project-picker")!
  const _component_user_circle_icon = _resolveComponent("user-circle-icon")!
  const _component_sign_out_icon = _resolveComponent("sign-out-icon")!
  const _component_popover = _resolveComponent("popover")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_create_project = _resolveComponent("create-project")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([
      _ctx.$style.container,
      _ctx.fullscreen ? _ctx.$style.fullscreen : '',
      _ctx.isProjectsView ? _ctx.$style.dark : '',
    ])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.content)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.headerText)
        }, [
          (_ctx.isProjectsView)
            ? (_openBlock(), _createElementBlock("img", _hoisted_1))
            : (_openBlock(), _createElementBlock("img", _hoisted_2))
        ], 2),
        _createElementVNode("ul", {
          class: _normalizeClass(_ctx.$style.menu)
        }, [
          _withDirectives(_createElementVNode("li", {
            id: "picker-menu",
            class: _normalizeClass(_ctx.$style.user)
          }, [
            _createVNode(_component_project_picker, { dark: _ctx.isProjectsView }, null, 8, ["dark"])
          ], 2), [
            [_vShow, _ctx.$router.currentRoute.value.params.projectId]
          ]),
          _createElementVNode("li", {
            id: "headerUserBtn",
            class: _normalizeClass(_ctx.$style.user)
          }, [
            _createVNode(_component_user_circle_icon, {
              class: _normalizeClass(_ctx.$style.userIcon),
              width: "1.25em",
              height: "1.25em"
            }, null, 8, ["class"]),
            _createElementVNode("h5", {
              class: _normalizeClass(_ctx.$style.headerUsername)
            }, _toDisplayString(_ctx.getUser()), 3)
          ], 2)
        ], 2)
      ], 2)
    ], 2),
    _createVNode(_component_popover, {
      target: "headerUserBtn",
      class: _normalizeClass(_ctx.$style.popover),
      arrow: true,
      toggleListeners: ['click'],
      options: { placement: 'bottom' },
      modifiers: [{ name: 'offset', options: { offset: [-30, -6] } }]
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", {
          class: _normalizeClass(_ctx.$style.popoverList)
        }, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: `https://infoland.ambita.com/#/bruker/${_ctx.getUserLogonIdent()}`,
              target: "_blank"
            }, " Min side ", 8, _hoisted_3)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args))),
              onKeyup: [
                _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)), ["enter"])),
                _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)), ["space"]))
              ]
            }, [
              _createVNode(_component_sign_out_icon, {
                class: _normalizeClass(_ctx.$style.popoverListIcon),
                width: "22",
                height: "22"
              }, null, 8, ["class"]),
              _cache[6] || (_cache[6] = _createTextVNode(" Logg ut "))
            ], 32)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_popover, {
      target: "picker-menu",
      class: _normalizeClass(_ctx.$style.popover),
      arrow: true,
      toggleListeners: ['click'],
      options: { placement: 'bottom' },
      modifiers: [{ name: 'offset', options: { offset: [0, 11] } }]
    }, {
      default: _withCtx(() => [
        _createElementVNode("ul", {
          class: _normalizeClass(_ctx.$style.popoverList)
        }, [
          (_ctx.projectStore.projectId)
            ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                _createVNode(_component_router_link, {
                  to: `/prosjekter/${_ctx.projectStore.projectId}/Prosjektoversikt`
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Prosjektoversikt ")
                  ])),
                  _: 1
                }, 8, ["to"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: "/prosjekter" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Alle prosjekter")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("div", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showCreateProjectModal && _ctx.showCreateProjectModal(...args))),
              onKeyup: [
                _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_ctx.showCreateProjectModal && _ctx.showCreateProjectModal(...args)), ["enter"])),
                _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => (_ctx.showCreateProjectModal && _ctx.showCreateProjectModal(...args)), ["space"]))
              ]
            }, [
              _createVNode(_component_icon_plus, {
                class: _normalizeClass(_ctx.$style.popoverListIcon),
                width: "22",
                height: "22"
              }, null, 8, ["class"]),
              _cache[9] || (_cache[9] = _createTextVNode(" Nytt prosjekt "))
            ], 32)
          ])
        ], 2)
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.isProjectModalOpen)
      ? (_openBlock(), _createBlock(_component_create_project, {
          key: 0,
          onCloseModal: _ctx.closeCreateProjectModal,
          onCreate: _ctx.createProject
        }, null, 8, ["onCloseModal", "onCreate"]))
      : _createCommentVNode("", true)
  ], 64))
}