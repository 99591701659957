import { DriveStep } from 'driver.js';

const tourSteps: DriveStep[] = [
  {
    element: 'body',
    popover: {
      title: 'Ingen produkt guide for denne siden enda.',
      description:
        'Vi har registert interessen og jobber aktivt med å legge til nye.',
      side: 'right',
      align: 'start',
    },
  },
];

export default tourSteps;
