import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")!
  const _component_list = _resolveComponent("list")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!

  return (_ctx.ownerships)
    ? (_openBlock(), _createBlock(_component_panel_row, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_panel_column, { title: "Eiere" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expandable ? 'list-collapsible-block' : 'div'), {
                label: "Eiere",
                class: _normalizeClass({
          'owner-list--complex': _ctx.expandable,
          'owner-list--simple': !_ctx.expandable,
        }),
                count: _ctx.ownerships.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ownerships, (owner) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: owner }, [
                          (owner.ownerType.code === 'CADASTRE')
                            ? (_openBlock(), _createBlock(_component_list_item, {
                                key: 0,
                                title: owner.ownerName(),
                                link: `${_ctx.projectStore.getProjectRoute}/${owner
                .getOwningCadastreIdent()
                .key()}`
                              }, null, 8, ["title", "link"]))
                            : (_openBlock(), _createElementBlock("li", _hoisted_1, _toDisplayString(owner.ownerName()), 1))
                        ], 64))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["class", "count"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}