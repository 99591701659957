import { LoadingEvent } from '@/utilities/CustomEvents';

const DATUM = 'EPSG:4326';
const ENDPOINT = 'https://wfs.geonorge.no/skwms1/wfs.planomriss';

function createFilter(lat: number, long: number): string {
  let gml =
    '<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">';
  gml += '<Contains>';
  gml += '<PropertyName>område</PropertyName>';
  gml += `<gml:Point srsName="${DATUM}">`;
  gml += `<gml:coordinates>${long},${lat}</gml:coordinates>`;
  gml += '</gml:Point>';
  gml += '</Contains>';
  gml += '</Filter>';

  return encodeURIComponent(gml);
}

function createParams(type: string, filter: string): string {
  return (
    '?outputformat=text/xml;%20subtype=gml/3.2.1&service=WFS&request=GetFeature&version=1.1.0' +
    `&typename=${encodeURIComponent(type)}&srsname=${DATUM}&FILTER=${filter}`
  );
}

function get(type: string, lat: number, long: number): Promise<Document> {
  const url = `${ENDPOINT}${createParams(type, createFilter(lat, long))}`;

  return new Promise<Document>((resolve, reject) => {
    fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'text/xml',
      },
    })
      .then((response) => response.text())
      .then((xmlString) => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(xmlString, 'text/xml');

        if (
          dom.documentElement.nodeName === 'parsererror' &&
          dom.firstElementChild?.nodeName !== 'gml:FeatureCollection'
        ) {
          throw new Error(`Failed to parse XML: ${xmlString}`);
        }

        resolve(dom);
      })
      .catch((e) => {
        const loadingErrorEvent = new LoadingEvent('loadingError');
        loadingErrorEvent.details = Object.prototype.hasOwnProperty.call(
          e,
          'message'
        )
          ? e.message
          : `${e}`;
        document.dispatchEvent(loadingErrorEvent);

        reject(e);
      });
  });
}

function getFirstElementFromDOM(
  DOM: Element,
  element: string,
  prefix = 'app:'
): string | null {
  const match = DOM.getElementsByTagName(`${prefix}${element}`);

  if (match && match.length > 0 && match[0]) {
    return match[0].innerHTML;
  }

  return null;
}

export interface GeonorgePlan {
  type: string;
  firstDigitalizationDate: string | null;
  updatedDate: string | null;
  link: string | null;
  municipalityNumber: string | null;
  planID: string | null;
  planType: string | null;
  planStatus: string | null;
  planDecision: string | null;
  lawReference: string | null;
  planName: string | null;
}

function getPlans(xml: Document, root: string, type: string): GeonorgePlan[] {
  const plans = xml.getElementsByTagName(root);
  const result: GeonorgePlan[] = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < plans.length; ++i) {
    result.push({
      type,
      firstDigitalizationDate: getFirstElementFromDOM(
        plans[i],
        'førsteDigitaliseringsdato'
      ),
      updatedDate: getFirstElementFromDOM(plans[i], 'oppdateringsdato'),
      link: getFirstElementFromDOM(plans[i], 'link'),
      municipalityNumber: getFirstElementFromDOM(plans[i], 'kommunenummer'),
      planID: getFirstElementFromDOM(plans[i], 'planidentifikasjon'),
      planType: getFirstElementFromDOM(plans[i], 'plantype'),
      planStatus: getFirstElementFromDOM(plans[i], 'planstatus'),
      planDecision: getFirstElementFromDOM(plans[i], 'planbestemmelse'),
      lawReference: getFirstElementFromDOM(plans[i], 'lovreferanse'),
      planName: getFirstElementFromDOM(plans[i], 'plannavn'),
    });
  }

  return result;
}

interface GeoCache {
  promise: Promise<GeonorgePlan[]>;
  data: Array<GeonorgePlan>;
}

const geoCache: Record<string, GeoCache> = {};

function getCached(key: string): GeoCache | null {
  if (Object.prototype.hasOwnProperty.call(geoCache, key)) {
    return geoCache[key];
  }

  return null;
}

function getPlan(
  selector: string,
  lat: number,
  long: number,
  title: string
): Promise<GeonorgePlan[]> {
  const key = `${selector}-${lat}-${long}`;
  const geo = getCached(key);

  if (geo) {
    return geo.promise
      .then(() => {
        if (geo && geo.data) {
          return Promise.resolve<Array<GeonorgePlan>>(geo.data.map((d) => d));
        }

        return Promise.resolve([]);
      })
      .catch((error) => Promise.reject(error));
  }

  geoCache[key] = {
    promise: new Promise<GeonorgePlan[]>((resolve, reject) => {
      get(selector, lat, long)
        .then((xml) => {
          geoCache[key].data = getPlans(xml, selector, title);
          resolve(geoCache[key].data.map((d) => d));
        })
        .catch((error) => {
          delete geoCache[key];
          reject(error);
        });
    }),
    data: [],
  };

  return geoCache[key].promise;
}

export function getMunicipalityPlans(
  lat: number,
  long: number
): Promise<GeonorgePlan[]> {
  return getPlan('app:KpOmråde', lat, long, 'Kommuneplan');
}

export function getZoningPlans(
  lat: number,
  long: number
): Promise<GeonorgePlan[]> {
  return getPlan('app:RpOmråde', lat, long, 'Reguleringsplan');
}
