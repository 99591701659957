import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ToggleSwitchWrap" }
const _hoisted_2 = { class: "ToggleSwitchPad" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aux_toggle = _resolveComponent("aux-toggle")!
  const _component_vertical_bar_chart = _resolveComponent("vertical-bar-chart")!
  const _component_number_text = _resolveComponent("number-text")!
  const _component_data_loader = _resolveComponent("data-loader")!

  return (_openBlock(), _createBlock(_component_data_loader, {
    loading: _ctx.loading,
    error: _ctx.error,
    onLoad: _ctx.load
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.hideGraph)
          ? (_openBlock(), _createBlock(_component_aux_toggle, {
              key: 0,
              checked: _ctx.displayNumbers,
              label: "Vis verdier i:",
              offLabel: "Prosent",
              onLabel: "Antall",
              onChange: _cache[0] || (_cache[0] = () => _ctx.toggleMode())
            }, null, 8, ["checked"]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.hideGraph)
        ? (_openBlock(), _createBlock(_component_vertical_bar_chart, {
            key: 0,
            percent: _ctx.percentData,
            numbers: _ctx.numberData,
            showNumbers: _ctx.displayNumbers
          }, null, 8, ["percent", "numbers", "showNumbers"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.textData), (key, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (Object.prototype.hasOwnProperty.call(_ctx.textData, key))
            ? (_openBlock(), _createBlock(_component_number_text, {
                key: 0,
                number: _ctx.textData[key],
                text: key
              }, null, 8, ["number", "text"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    _: 1
  }, 8, ["loading", "error", "onLoad"]))
}