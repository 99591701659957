
import { defineComponent } from 'vue';
import AngleIcon from '@icons/regular/caret-down.svg';

export default defineComponent({
  props: {
    size: {
      type: String,
      default: 'medium',
      required: false,
    },
  },
  components: {
    AngleIcon,
  },
});
