import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBackButton = _resolveComponent("PanelBackButton")!
  const _component_PanelSpinner = _resolveComponent("PanelSpinner")!
  const _component_demography = _resolveComponent("demography")!
  const _component_tab = _resolveComponent("tab")!
  const _component_tabs = _resolveComponent("tabs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PanelBackButton, {
      to: `${_ctx.projectStore.getProjectRoute}/valgte`,
      label: "Oppsummering"
    }, null, 8, ["to"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_PanelSpinner, { key: 0 }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Henter informasjon om demografi ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.panel.panel)
        }, [
          _createVNode(_component_tabs, { defaultIndex: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_tab, { title: "Mennesker" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Aldersfordeling",
                      area: "basicDistrict",
                      type: _ctx.types.age,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Utdanning",
                      area: "basicDistrict",
                      type: _ctx.types.education,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Utdanningsfelt",
                      area: "basicDistrict",
                      type: _ctx.types.educationalField,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Inntekt",
                      area: "basicDistrict",
                      type: _ctx.types.income,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Formue",
                      area: "basicDistrict",
                      type: _ctx.types.fortune,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Sivilstand",
                      area: "basicDistrict",
                      type: _ctx.types.maritalStatus,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Husholdninger",
                      area: "basicDistrict",
                      type: _ctx.types.houseHolds,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_tab, { title: "Boliger" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Boligtype",
                      area: "basicDistrict",
                      type: _ctx.types.buildingsType,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Bo- & arbeidsfordeling",
                      area: "basicDistrict",
                      type: _ctx.types.employees,
                      geometry: _ctx.geometry,
                      "hide-graph": ""
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Eiendomspriser",
                      area: "basicDistrict",
                      type: _ctx.types.propertyPrices,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Eiertype",
                      area: "basicDistrict",
                      type: _ctx.types.buildingsOwnerType,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Boligareal",
                      area: "basicDistrict",
                      type: _ctx.types.buildingsArea,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_tab, { title: "Hytter" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Hytteeiere",
                      area: "basicDistrict",
                      type: _ctx.types.cabinOwners,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_demography, {
                      title: "Hytter",
                      area: "basicDistrict",
                      type: _ctx.types.cabins,
                      geometry: _ctx.geometry
                    }, null, 8, ["type", "geometry"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}