import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "property-details-header" }
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "property-details-categories-list" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBackButton = _resolveComponent("PanelBackButton")!
  const _component_PanelSpinner = _resolveComponent("PanelSpinner")!
  const _component_AuxBanner = _resolveComponent("AuxBanner")!
  const _component_aux_spinner = _resolveComponent("aux-spinner")!
  const _component_star_filled_icon = _resolveComponent("star-filled-icon")!
  const _component_aux_button = _resolveComponent("aux-button")!
  const _component_star_icon = _resolveComponent("star-icon")!
  const _component_aux_banner = _resolveComponent("aux-banner")!
  const _component_printer_icon = _resolveComponent("printer-icon")!
  const _component_arrow_square_icon = _resolveComponent("arrow-square-icon")!
  const _component_crosshair_icon = _resolveComponent("crosshair-icon")!
  const _component_panel_hr = _resolveComponent("panel-hr")!
  const _component_panel_title = _resolveComponent("panel-title")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_owner_list = _resolveComponent("owner-list")!
  const _component_PanelYesNoLabel = _resolveComponent("PanelYesNoLabel")!
  const _component_panel_group = _resolveComponent("panel-group")!
  const _component_ValueEstimate = _resolveComponent("ValueEstimate")!
  const _component_DocumentsList = _resolveComponent("DocumentsList")!
  const _component_caret_right_icon = _resolveComponent("caret-right-icon")!
  const _component_plan_list = _resolveComponent("plan-list")!
  const _component_section_list = _resolveComponent("section-list")!
  const _component_buildings_list = _resolveComponent("buildings-list")!
  const _component_heritages_list = _resolveComponent("heritages-list")!
  const _component_soil_contaminations = _resolveComponent("soil-contaminations")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isSection())
      ? (_openBlock(), _createBlock(_component_PanelBackButton, {
          key: 0,
          to: `${_ctx.projectStore.getProjectRoute}/valgte`,
          label: "Oppsummering",
          onClick: _ctx.removeMarker
        }, null, 8, ["to", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isSection())
      ? (_openBlock(), _createBlock(_component_PanelBackButton, {
          key: 1,
          to: `${_ctx.projectStore.getProjectRoute}/${_ctx.getMainCadastre()}`,
          label: `Grunneiendom: ${_ctx.getMainCadastre()} `,
          onClick: _ctx.removeMarker
        }, null, 8, ["to", "label", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_PanelSpinner, { key: 2 }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Henter informasjon om eiendommen ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_AuxBanner, {
          key: 3,
          class: "error-banner",
          type: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.currentProperty !== null && !_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
          _createElementVNode("div", _hoisted_1, [
            _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Eiendomsinformasjon", -1)),
            (_ctx.savingProperty)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.panelStyle.w20)
                }, [
                  _createVNode(_component_aux_spinner, { size: "small" })
                ], 2))
              : (_ctx.isSaved)
                ? (_openBlock(), _createBlock(_component_aux_button, {
                    key: 1,
                    onClick: _ctx.deleteProperty,
                    size: "small",
                    "tour-id": "toggle-property-save-state"
                  }, {
                    left: _withCtx(() => [
                      _createVNode(_component_star_filled_icon, { width: "1.5em" })
                    ]),
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createTextVNode(" Fjern tomt "))
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_aux_button, {
                    key: 2,
                    onClick: _ctx.saveProperty,
                    size: "small",
                    "tour-id": "toggle-property-save-state"
                  }, {
                    left: _withCtx(() => [
                      _createVNode(_component_star_icon, { width: "1.5em" })
                    ]),
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createTextVNode(" Lagre tomt "))
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
          ]),
          (_ctx.savePropertyError)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.panelStyle.errorBanner)
              }, [
                _createVNode(_component_aux_banner, { type: "error" }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Klarte ikke oppdatere eiendom.")
                  ])),
                  _: 1
                })
              ], 2))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: "property-details-button",
            "data-testid": _ctx.getTestid('topBtns')
          }, [
            _createVNode(_component_aux_button, {
              onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('generateReport', _ctx.currentProperty?.cadastreKey())),
              size: "small",
              "tour-id": "generate-report"
            }, {
              left: _withCtx(() => [
                _createVNode(_component_printer_icon)
              ]),
              default: _withCtx(() => [
                _cache[8] || (_cache[8] = _createTextVNode(" Rapport "))
              ]),
              _: 1
            }),
            _createVNode(_component_aux_button, {
              onClick: _ctx.goToStreetView,
              size: "small"
            }, {
              left: _withCtx(() => [
                _createVNode(_component_arrow_square_icon)
              ]),
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createTextVNode(" Google streetview "))
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_aux_button, {
              onClick: _ctx.displayPropertyInMap,
              size: "small"
            }, {
              left: _withCtx(() => [
                _createVNode(_component_crosshair_icon)
              ]),
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createTextVNode(" Vis i kartet "))
              ]),
              _: 1
            }, 8, ["onClick"])
          ], 8, _hoisted_2),
          _createVNode(_component_panel_hr),
          _createElementVNode("div", null, [
            (_ctx.currentProperty.getPolygon() === null)
              ? (_openBlock(), _createBlock(_component_AuxBanner, {
                  key: 0,
                  class: "error-banner",
                  type: "warning"
                }, {
                  default: _withCtx(() => [
                    _cache[11] || (_cache[11] = _createTextVNode(" Eiendommen kan ikke vises i kartet. ")),
                    (_ctx.currentProperty.sections && _ctx.currentProperty.sections.length > 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" Se seksjon for tilhørende tomtegrenser. ")
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_panel_title, { title: "Om eiendommen" }),
            _createVNode(_component_panel_row, { columns: "2" }, {
              default: _withCtx(() => [
                _createVNode(_component_panel_column, { title: "Matrikkelnummer" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentProperty.cadastre.key), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_panel_column, { title: "Grunneiendom" }, {
                  default: _withCtx(() => [
                    (_ctx.isSection())
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: `${_ctx.projectStore.getProjectRoute}/${_ctx.getMainCadastre()}`
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getMainCadastre()), 1)
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    (
              _ctx.getMainCadastre() === _ctx.currentProperty.cadastre.key ||
              _ctx.getMainCadastre() === null
            )
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Ja "))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_panel_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_panel_column, { title: "Adresse" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentProperty.addressText()
              ? _ctx.currentProperty.addressText()
              : 'Ingen adresse'), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (
          _ctx.currentProperty.getCadastre() &&
          _ctx.currentProperty.getCadastre().cityDistrict
        )
              ? (_openBlock(), _createBlock(_component_panel_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_panel_column, { title: "Område / Bydel" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.currentProperty.getCadastre().cityDistrict.name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_panel_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_panel_column, { title: "Areal" }, {
                  default: _withCtx(() => [
                    (
              _ctx.currentProperty.getCadastre().specifiedArea === 0 ||
              _ctx.currentProperty.getCadastre().specifiedArea === null
            )
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode("Areal ikke registrert ")
                        ], 64))
                      : _createCommentVNode("", true),
                    (_ctx.currentProperty.getCadastre().specifiedArea !== 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.NumberFormat.format(_ctx.currentProperty.getCadastre().specifiedArea)) + " m²", 1),
                          (_ctx.currentProperty.getCadastre().numberOfPlots > 0)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(" fordelt over " + _toDisplayString(_ctx.currentProperty.getCadastre().numberOfPlots) + " " + _toDisplayString(_ctx.currentProperty.getCadastre().numberOfPlots > 1
                  ? 'teiger'
                  : 'teig'), 1)
                              ], 64))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (
          _ctx.currentProperty.ownerships && _ctx.currentProperty.ownerships.length > 0
        )
              ? (_openBlock(), _createBlock(_component_owner_list, {
                  key: 2,
                  ownerships: _ctx.currentProperty.ownerships
                }, null, 8, ["ownerships"]))
              : _createCommentVNode("", true),
            _createVNode(_component_panel_group, { title: "Eiendomstype" }, {
              default: _withCtx(() => [
                _createVNode(_component_panel_row, { columns: "3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_panel_column, { title: "Seksjonert" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PanelYesNoLabel, {
                          condition: _ctx.currentProperty.cadastre.isSectioned
                        }, null, 8, ["condition"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_panel_column, { title: "Vannteig" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PanelYesNoLabel, {
                          condition: _ctx.currentProperty.getCadastre().isVannteig()
                        }, null, 8, ["condition"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_panel_column, { title: "Eierløs teig" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PanelYesNoLabel, {
                          condition: _ctx.currentProperty.getCadastre().isEierlos()
                        }, null, 8, ["condition"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (
          _ctx.currentProperty.latestConveyance?.dateRegistered &&
          _ctx.currentProperty.latestConveyance?.conveyanceType
        )
              ? (_openBlock(), _createBlock(_component_panel_group, {
                  key: 3,
                  title: "Siste omsetning"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_panel_row, { columns: "2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_panel_column, { title: "Sist omsatt" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.currentProperty.latestConveyance.dateRegistered.ddmmyyyy), 1)
                          ]),
                          _: 1
                        }),
                        (
              _ctx.currentProperty.latestConveyance &&
              _ctx.currentProperty.latestConveyance.purchasePrice > 0
            )
                          ? (_openBlock(), _createBlock(_component_panel_column, {
                              key: 0,
                              title: "Omsatt for"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(`${_ctx.NumberFormat.format(
                _ctx.currentProperty.latestConveyance.purchasePrice
              )} kr`) + " ", 1),
                                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                                _createTextVNode(" (" + _toDisplayString(_ctx.conveyanceType) + ") ", 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.showValueEstimate)
              ? (_openBlock(), _createBlock(_component_ValueEstimate, { key: 4 }))
              : _createCommentVNode("", true),
            _createVNode(_component_panel_group, {
              title: "Dokumenter",
              "tour-id": "documents-accordion"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DocumentsList, {
                  cadastre: _ctx.currentProperty.getCadastre()
                }, null, 8, ["cadastre"])
              ]),
              _: 1
            }),
            _createVNode(_component_panel_group, {
              title: "Mer informasjon",
              "tour-id": "more-details"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.currentProperty.getCadastre())
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.currentProperty.getCadastre().key)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              class: "propertypanel-link-block",
                              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                _ctx.$router.push(`./${_ctx.currentProperty?.getCadastre().key}/notater`)
              ), ["prevent"]))
                            }, [
                              _cache[13] || (_cache[13] = _createElementVNode("span", null, "Notater", -1)),
                              _createVNode(_component_caret_right_icon, {
                                width: "30",
                                height: "30",
                                class: _normalizeClass(_ctx.panelStyle.details__list__item__arrow)
                              }, null, 8, ["class"])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("button", {
                          class: "propertypanel-link-block",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (
                _ctx.$emit('showDemography', _ctx.currentProperty?.getCadastre())
              ), ["prevent"]))
                        }, [
                          _cache[14] || (_cache[14] = _createElementVNode("span", null, "Demografi", -1)),
                          _createVNode(_component_caret_right_icon, {
                            width: "30",
                            height: "30",
                            class: _normalizeClass(_ctx.panelStyle.details__list__item__arrow)
                          }, null, 8, ["class"])
                        ]),
                        _createElementVNode("a", {
                          class: "propertypanel-link-block",
                          target: "_blank",
                          href: `https://infoland.ambita.com/#/product?cadastre=${
                _ctx.currentProperty?.getCadastre().key
              }`
                        }, [
                          _cache[15] || (_cache[15] = _createElementVNode("span", null, "Åpne eiendom i Infoland", -1)),
                          _createVNode(_component_arrow_square_icon, {
                            width: "30",
                            height: "30",
                            class: _normalizeClass(_ctx.panelStyle.details__list__item__arrow)
                          }, null, 8, ["class"])
                        ], 8, _hoisted_5)
                      ], 64))
                    : _createCommentVNode("", true),
                  (_ctx.currentProperty.getCadastre())
                    ? (_openBlock(), _createBlock(_component_plan_list, {
                        key: 1,
                        type: "Kommuneplaner",
                        cadastre: _ctx.currentProperty.getCadastre()
                      }, null, 8, ["cadastre"]))
                    : _createCommentVNode("", true),
                  (_ctx.currentProperty.getCadastre())
                    ? (_openBlock(), _createBlock(_component_plan_list, {
                        key: 2,
                        type: "Reguleringsplaner",
                        cadastre: _ctx.currentProperty.getCadastre()
                      }, null, 8, ["cadastre"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_section_list, {
                    cadastre: _ctx.currentProperty.getCadastre(),
                    sections: _ctx.currentProperty.sections || []
                  }, null, 8, ["cadastre", "sections"]),
                  _createVNode(_component_buildings_list, {
                    cadastre: _ctx.currentProperty.getCadastre(),
                    buildings: _ctx.currentProperty.buildings || []
                  }, null, 8, ["cadastre", "buildings"]),
                  _createVNode(_component_heritages_list, {
                    cadastre: _ctx.currentProperty.getCadastre(),
                    heritages: _ctx.currentProperty.heritages || []
                  }, null, 8, ["cadastre", "heritages"]),
                  _createVNode(_component_soil_contaminations, {
                    cadastre: _ctx.currentProperty.getCadastre(),
                    soilContaminations: _ctx.currentProperty?.soilContaminations || []
                  }, null, 8, ["cadastre", "soilContaminations"])
                ])
              ]),
              _: 1
            })
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}