import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';
import { DriveStep } from 'driver.js';
import tours from '@/tours';

type TourType = typeof tours;
type TourNames = keyof TourType;
const TOUR_STORAGE_KEY = 'pf-onboarding-tours';
const WEEK_IN_MS = 1000 * 60 * 60 * 24 * 7;

export const useOnboardingStore = defineStore('onboarding', () => {
  const tourState = ref<{
    [key: string]: {
      state: 'complete' | 'skipped' | 'started';
      modifiedDate: number;
      count: number;
    };
  }>({});
  const steps = ref<DriveStep[]>([]);
  const currentTour = ref<keyof TourType | null>(null);

  const _loadTourState = (() => {
    const storageValue = localStorage.getItem(TOUR_STORAGE_KEY);
    if (storageValue) {
      tourState.value = JSON.parse(storageValue);
    } else {
      tourState.value = {};
    }
  })();

  const getTourList = computed(() => {
    return Object.keys(tours) as TourNames[];
  });

  const completeTour = (tourname: string) => {
    steps.value = [];

    tourState.value[tourname] = {
      count: 1,
      state: 'complete',
      modifiedDate: Date.now(),
    };
  };

  const skipTour = (tourname: string) => {
    let tourStatus = tourState.value[tourname];

    if (!tourStatus) {
      tourStatus = {
        count: 0,
        state: 'skipped',
        modifiedDate: Date.now(),
      };
    }

    tourStatus.count += 1;

    steps.value = [];
    tourState.value[tourname] = tourStatus;
  };

  const skipCurrentTour = () => {
    if (currentTour.value) {
      skipTour(currentTour.value);
    } else {
      steps.value = [];
    }
  };

  const completeCurrentTour = () => {
    if (currentTour.value) {
      completeTour(currentTour.value);
    } else {
      steps.value = [];
    }
  };

  const forceStartTour = <T extends keyof TourType>(tourName: T): void => {
    currentTour.value = tourName;
    steps.value = tours[tourName];
  };

  const startTour = <T extends keyof TourType>(
    tourName?: T,
    delayInMs = 0
  ): void => {
    const nowInMS = Date.now();
    if (tourName) {
      let tourStatus = tourState.value[tourName];

      if (!tourStatus) {
        tourStatus = {
          count: 0,
          state: 'started',
          modifiedDate: nowInMS,
        };
      }

      const restartSchedulePassed =
        nowInMS - tourStatus.modifiedDate > WEEK_IN_MS;

      const shouldSkipBecauseOfTime =
        tourStatus.state === 'skipped' && !restartSchedulePassed;
      const skipCountOverThreadshold =
        tourStatus.state === 'skipped' && tourStatus.count >= 2;
      const hasCompleted = tourStatus.state === 'complete';
      if (
        !hasCompleted &&
        !shouldSkipBecauseOfTime &&
        !skipCountOverThreadshold
      ) {
        setTimeout(() => {
          forceStartTour(tourName);
        }, delayInMs);
      }
    }
  };

  watch(tourState.value, () => {
    localStorage.setItem(TOUR_STORAGE_KEY, JSON.stringify(tourState.value));
  });

  return {
    steps,
    currentTour,
    startTour,
    completeCurrentTour,
    skipCurrentTour,
    forceStartTour,
    completeTour,
    skipTour,
    getTourList,
  };
});
