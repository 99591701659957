import { DriveStep } from 'driver.js';

const tourSteps: DriveStep[] = [
  {
    element: 'sun-shade-analysis',
    popover: {
      title: 'Se hvordan solen faller på tomten',
      description:
        'Spol gjennom et helt døgn eller se hvordan sommersolen ser ut på tomten.',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: 'sight-lines',
    popover: {
      title: 'Sjekk utsikten',
      description:
        'Tegn siktlinjer fra et utkikkspunkt og se om noe blokkerer for utsikten',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: 'height-curve',
    popover: {
      title: 'Mål høyder',
      description: 'Mål høydeforskjell mellom to punkter, og se høydekurver',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: 'length-measurement',
    popover: {
      title: 'Mål lengder',
      description: 'Tegn en linje mellom to punkter og få ut mål i meter',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: 'area-measurement',
    popover: {
      title: 'Mål areal og omkrets',
      description: 'Tegn en flate og beregn flatens areal og omkrets',
      side: 'bottom',
      align: 'start',
    },
  },
  {
    element: 'insert-building',
    popover: {
      title: 'Visualiser ditt prosjekt på tomten',
      description:
        'Sett inn ditt nye hus (IFC BIM-modell), skjul eksisterende bygninger og ta skjermbilder til presentasjoner',
      side: 'right',
      align: 'start',
    },
  },
];

export default tourSteps;
