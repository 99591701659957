
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'VerticalBarChart',
  props: {
    percent: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    },
    numbers: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    },
    showNumbers: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    max(): number {
      let max = Number.NEGATIVE_INFINITY;
      const keys = Object.keys(this.percent);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; ++i) {
        if (Object.prototype.hasOwnProperty.call(this.percent, keys[i])) {
          max = Math.max(this.percent[keys[i]], max);
        }
      }

      return max;
    },
    maxNum(): number {
      let max = Number.NEGATIVE_INFINITY;
      const keys = Object.keys(this.numbers);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keys.length; ++i) {
        if (Object.prototype.hasOwnProperty.call(this.numbers, keys[i])) {
          max = Math.max(this.numbers[keys[i]], max);
        }
      }

      return max;
    },
    values(): Record<string, number | string>[] {
      const numberKeys = this.numbers ? Object.keys(this.numbers) : [];
      const percentKeys = this.percent ? Object.keys(this.percent) : [];
      const result: Record<string, number | string>[] = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < percentKeys.length; ++i) {
        const hasKey = Object.prototype.hasOwnProperty.call(
          this.percent,
          percentKeys[i]
        );

        if (hasKey) {
          let number: number | string = '?';

          if (numberKeys.length > i) {
            number = this.numbers[numberKeys[i]];
          }

          result.push({
            description: percentKeys[i],
            percent: `${this.percent[percentKeys[i]]}%`,
            number,
            barWidth: Math.round(
              (this.percent[percentKeys[i]] / this.max) * 100
            ),
          });
        }
      }

      return result;
    },
  },
});
