
import { defineComponent, PropType } from 'vue';
import Building from '@/data/wrapper/Building';
import Cadastre from '@/data/wrapper/Cadastre';
import { useProjectStore } from '@/store/project';
import BuildingListItem from './BuildingListItem.vue';
import ListCollapsibleBlock from './Blocks/ListCollapsibleBlock.vue';
import List from './Blocks/List.vue';

export default defineComponent({
  name: 'Buildings',
  components: {
    BuildingListItem,
    ListCollapsibleBlock,
    List,
  },
  props: {
    buildings: {
      type: Object as PropType<Building[]>,
      required: true,
    },
    cadastre: {
      type: Object as PropType<Cadastre>,
      required: true,
    },
  },
  setup() {
    const projectStore = useProjectStore();

    // TODO: move to composable
    const handleMouseEnter = (building: Building) => {
      if (building?.position.geometry.coordinates) {
        const [lng, lat] = building.position.geometry.coordinates;

        projectStore.movePointer(lat, lng);
      }
    };

    const handleMouseLeave = (): void => {
      projectStore.removePointer();
    };

    return {
      handleMouseEnter,
      handleMouseLeave,
    };
  },
});
