import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "count-wrapper"
}
const _hoisted_2 = { class: "count-wrapper" }
const _hoisted_3 = { class: "count-wrapper" }

import Accordion from '@/components/Accordion/Accordion.vue';
import { AuxTag, AuxSpinner } from '@ambita/design-system';



export default /*@__PURE__*/_defineComponent({
  __name: 'ListCollapsibleBlock',
  props: {
  label: {
    type: String,
    required: true,
  },
  count: {
    type: Number,
    required: true,
  },
  color: {
    type: String,
    required: false,
    default: 'purple-1',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.count > 0 && !__props.loading && !__props.error)
      ? (_openBlock(), _createBlock(Accordion, _mergeProps({
          key: 0,
          size: "large"
        }, _ctx.$attrs), {
          summary: _withCtx(() => [
            (__props.count)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_unref(AuxTag), { color: __props.color }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(__props.count), 1)
                    ]),
                    _: 1
                  }, 8, ["color"])
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(__props.label), 1)
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 16))
      : _createCommentVNode("", true),
    (__props.count === 0 && !__props.loading && !__props.error)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 1,
          class: "list-collapsible-block list-collapsible-block--no-items"
        }, _ctx.$attrs), [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(AuxTag), null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.count), 1)
              ]),
              _: 1
            })
          ]),
          _createTextVNode(" " + _toDisplayString(__props.label), 1)
        ], 16))
      : _createCommentVNode("", true),
    (__props.loading && !__props.error)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 2,
          class: "list-collapsible-block list-collapsible-block--loading-items"
        }, _ctx.$attrs), [
          _createVNode(_unref(AuxSpinner), { size: "small" }),
          _createTextVNode(" " + _toDisplayString(__props.label), 1)
        ], 16))
      : _createCommentVNode("", true),
    (__props.error && !__props.loading)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 3,
          class: "list-collapsible-block list-collapsible-block--error"
        }, _ctx.$attrs), [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(AuxTag), { color: "red" }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("-")
              ])),
              _: 1
            })
          ]),
          _createTextVNode(" " + _toDisplayString(__props.label) + " ", 1),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "list-collapsible-block-error-label" }, "(feil på tjeneste)", -1))
        ], 16))
      : _createCommentVNode("", true)
  ], 64))
}
}

})