import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, watch } from 'vue';
import { Driver, driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import { useOnboardingStore } from '@/store/onboarding';
import PTM from '@/utilities/PTM';
import { useRoute } from 'vue-router';
import logger from '@/utilities/Logger';

const EVENT_CATEGORY = 'Onboarding';

enum Actions {
  skip = 'Skipped tour',
  start = 'Start tour',
  newTour = 'Want new tour',
  next = 'View next step',
  previous = 'View previous step',
  completed = 'Completed tour',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Onboarding',
  setup(__props) {

const onboardingStore = useOnboardingStore();
const route = useRoute();
const getTourID = (id: string) => {
  return `[tour-id=${id}]`;
};

const steps = computed(() => {
  return onboardingStore.steps.map((step) => {
    const newStep = structuredClone(step);

    if (typeof newStep.element === 'string') {
      newStep.element = getTourID(newStep.element);
    }

    return newStep;
  });
});

const logProgress = (action: string, driverObj: Driver) => {
  if (onboardingStore.currentTour) {
    const stepName = driverObj.getActiveStep()?.element;
    const stepIndex = driverObj.getActiveIndex() || 0;
    const stepTotal = onboardingStore.steps.length;
    const isDefaultTour = onboardingStore.currentTour === 'defaultTour';

    if (stepName === undefined && stepTotal === 0) {
      return;
    }

    if (isDefaultTour && action !== Actions.start) {
      return;
    }

    if (isDefaultTour) {
      PTM.event(EVENT_CATEGORY, Actions.newTour, route.path);
    } else {
      PTM.event(
        EVENT_CATEGORY,
        onboardingStore.currentTour,
        `${action} - ${stepIndex + 1}/${stepTotal} - ${stepName}`
      );
    }
  }
};

watch(
  () => onboardingStore.steps,
  (value) => {
    if (value.length === 0) {
      return;
    }

    try {
      const driverObj = driver({
        showProgress: true,
        showButtons: ['next', 'previous', 'close'],
        steps: steps.value,
        nextBtnText: 'Neste',
        prevBtnText: 'Forrige',
        doneBtnText: 'Avslutt',
        progressText: '{{current}} av {{total}}',
        onPopoverRender: () => {
          if (driverObj.getActiveIndex() === 0) {
            logProgress(Actions.start, driverObj);
          }
        },
        onNextClick: () => {
          driverObj.moveNext();
          logProgress(Actions.next, driverObj);
        },
        onPrevClick: () => {
          driverObj.movePrevious();
          logProgress(Actions.previous, driverObj);
        },
        onDestroyStarted: () => {
          if (driverObj.isLastStep()) {
            logProgress(Actions.completed, driverObj);
            onboardingStore.completeCurrentTour();
          } else {
            logProgress(Actions.skip, driverObj);
            onboardingStore.skipCurrentTour();
          }

          driverObj.destroy();
        },
      });

      driverObj.drive();
    } catch (error) {
      logger.error('Error occured', error);
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})