/* eslint-disable import/no-cycle */
// @TODO: fix dependency cycle
import PropFinderMap from '@/components/Map/PropfinderMap';
/* eslint-enable import/no-cycle */

import { LatLngExpression } from 'leaflet';
import {
  RealtyBuildings,
  RealtyDate,
  RealtyMunicipality,
  RealtyFeature,
  RealtyGeoJSON,
  RealtyType,
  RealtyFloor,
  RealtyBuildingHistory,
  RealtyLink,
} from '../RealtyTypes';

export default class Building implements RealtyBuildings {
  public ident: {
    buildingNumber: number;
  };

  public key: string;

  public updatedDate: RealtyDate;

  public municipalityNumber: number;

  public municipality: RealtyMunicipality;

  public numberOfUnits: number;

  public hasElevator: boolean;

  public numberOfFloors: number;

  public totalFloorSpace: number;

  public totalAreaOfPrimarySpace: number;

  public areaOfSecondarySpace: number;

  public incompleteArea: boolean;

  public builtUpArea: number;

  public totalArea: number;

  public totalAreaHousing: number;

  public totalAlternateArea: number;

  public position: RealtyFeature;

  public geojson: RealtyGeoJSON;

  public buildingStatus: RealtyType;

  public typeOfBuilding: RealtyType;

  public industryGroup: RealtyType;

  public waterSupplyType: RealtyType;

  public dischargeType: RealtyType;

  public originType: RealtyType;

  public heatingType: RealtyType[];

  public energySourceType: RealtyType[];

  public approvedDate: RealtyDate;

  public registeredApprovedDate: RealtyDate;

  public startedDate: RealtyDate;

  public registeredStartedDate: RealtyDate;

  public usedDate: RealtyDate;

  public registeredUsedDate: RealtyDate;

  public buildingStatusHistory: RealtyBuildingHistory[];

  public floors: RealtyFloor[];

  public links: {
    self: RealtyLink;
    singleheritages: RealtyLink;
    buildingchanges: RealtyLink;
    culturalheritages: RealtyLink;
    cadastres: RealtyLink;
    addresses: RealtyLink;
    units: RealtyLink;
    contacts: RealtyLink;
    developers: RealtyLink;
    floors: RealtyLink;
  };

  constructor(item: RealtyBuildings) {
    this.ident = item.ident;
    this.key = item.key;
    this.updatedDate = item.updatedDate;
    this.municipalityNumber = item.municipalityNumber;
    this.municipality = item.municipality;
    this.numberOfUnits = item.numberOfUnits;
    this.hasElevator = item.hasElevator;
    this.numberOfFloors = item.numberOfFloors;
    this.totalFloorSpace = item.totalFloorSpace;
    this.totalAreaOfPrimarySpace = item.totalAreaOfPrimarySpace;
    this.areaOfSecondarySpace = item.areaOfSecondarySpace;
    this.incompleteArea = item.incompleteArea;
    this.builtUpArea = item.builtUpArea;
    this.totalArea = item.totalArea;
    this.totalAreaHousing = item.totalAreaHousing;
    this.totalAlternateArea = item.totalAlternateArea;
    this.position = item.position;
    this.geojson = item.geojson;
    this.buildingStatus = item.buildingStatus;
    this.typeOfBuilding = item.typeOfBuilding;
    this.industryGroup = item.industryGroup;
    this.waterSupplyType = item.waterSupplyType;
    this.dischargeType = item.dischargeType;
    this.originType = item.originType;
    this.heatingType = item.heatingType;
    this.energySourceType = item.energySourceType;
    this.approvedDate = item.approvedDate;
    this.registeredApprovedDate = item.registeredApprovedDate;
    this.startedDate = item.startedDate;
    this.registeredStartedDate = item.registeredStartedDate;
    this.usedDate = item.usedDate;
    this.registeredUsedDate = item.registeredUsedDate;
    this.buildingStatusHistory = item.buildingStatusHistory;
    this.floors = item.floors;
    this.links = item.links;
  }

  public hasRammetillatelse(): boolean {
    return (
      this.buildingStatusHistory.filter(
        (status: RealtyBuildingHistory) => status.buildingStatus.code === 'RA'
      ).length > 0
    );
  }

  public hasFerdigattest(): boolean {
    return (
      this.buildingStatusHistory.filter(
        (status: RealtyBuildingHistory) => status.buildingStatus.code === 'FA'
      ).length > 0
    );
  }

  public buildingStatusText(): string {
    if (this.buildingStatus === null) {
      return '';
    }

    const statusText: string = this.buildingStatus.text;

    return (
      statusText.charAt(0).toUpperCase() + statusText.slice(1).toLowerCase()
    );
  }

  public industryGroupText(): string {
    if (!this.industryGroup) {
      return 'Ikke registert';
    }

    const { text } = this.industryGroup;

    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  public latLng(): LatLngExpression {
    if (this.position === null) {
      return {
        lat: 0,
        lng: 0,
      };
    }

    return {
      lat: this.position.geometry.coordinates[1],
      lng: this.position.geometry.coordinates[0],
    };
  }

  static isMainFloor(floor: RealtyFloor): number {
    if (floor.totalUsableArea) {
      return floor.ident.floorLevelCode.code === 'H'
        ? floor.totalUsableArea
        : 0;
    }

    return 0;
  }

  public getBuiltUpArea(): number {
    let totalArea = 0;
    const boligAreal = this.builtUpArea;

    if (
      this.areaOfSecondarySpace > 1 &&
      this.floors &&
      this.floors.length <= 1
    ) {
      totalArea = this.areaOfSecondarySpace;
    } else if (this.builtUpArea > this.areaOfSecondarySpace) {
      totalArea = this.builtUpArea + this.areaOfSecondarySpace;
    } else if (boligAreal) {
      const mainFloor = this.floors.find((floor) =>
        Building.isMainFloor(floor)
      );

      if (mainFloor && mainFloor.totalUsableArea) {
        totalArea = mainFloor.totalUsableArea;
      }
    }

    if (this.builtUpArea < 2 && this.floors && this.floors.length >= 1) {
      const mainFloor = this.floors.find((floor) =>
        Building.isMainFloor(floor)
      );

      if (mainFloor && mainFloor.totalUsableArea) {
        totalArea = mainFloor.totalUsableArea;
      }
    }

    return totalArea;
  }

  public isNotWithinMap(propFinderMap: PropFinderMap): boolean {
    return !this.isWithinMap(propFinderMap);
  }

  public isWithinMap(propFinderMap: PropFinderMap): boolean {
    return propFinderMap.bounds().contains(this.latLng());
  }
}
