import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_caret_left_icon = _resolveComponent("caret-left-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: _ctx.to }, {
    default: _withCtx(() => [
      _createVNode(_component_caret_left_icon, {
        width: "30",
        height: "30"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ]),
    _: 1
  }, 8, ["to"]))
}