import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, PropType } from 'vue';
import { useProjectStore } from '@/store/project';
import { useRoute } from 'vue-router';
import { formatDate } from '@ambita/design-system';
import Building from '@/data/wrapper/Building';
import ListItem from './Blocks/ListItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'BuildingListItem',
  props: {
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
},
  emits: ['activate', 'deactivate'],
  setup(__props) {

const props = __props;



const projectStore = useProjectStore();
const router = useRoute();

const buildingStatus = computed(() => {
  let buildingStatusDate: string | null;
  const label = props.building.buildingStatus.text;

  switch (label) {
    case 'RAMMETILLATELSE':
      buildingStatusDate = props.building.approvedDate?.isoDateTime || null;
      break;

    case 'IGANGSETTELSESTILLATELSE':
      buildingStatusDate = props.building.startedDate?.isoDateTime || null;
      break;

    case 'MIDLERTIDIG BRUKSTILLATELSE':
    case 'TATT I BRUK':
    case 'FERDIGATTEST':
      buildingStatusDate = props.building.usedDate?.isoDateTime || null;
      break;

    default:
      buildingStatusDate = null;
  }
  const formattedDate = formatDate(buildingStatusDate, 'date');

  return {
    label,
    date: formattedDate,
  };
});

const buildingType = computed(() => {
  return props.building.typeOfBuilding.text || 'Ukjent bygningstype';
});

const buildingURL = computed(() => {
  return `${projectStore.getProjectRoute}/${router.params.cadastreKey}/bygninger/${props.building.key}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ListItem, {
    title: buildingType.value,
    subtitle: `${buildingStatus.value.label} ${buildingStatus.value.date}`,
    link: buildingURL.value,
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('activate', __props.building))),
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('activate', __props.building))),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('deactivate'))),
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('deactivate')))
  }, null, 8, ["title", "subtitle", "link"]))
}
}

})