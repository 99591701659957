import { InjectionKey, Ref, ref } from 'vue';
import logger from '@/utilities/Logger';

export interface ProjectStage {
  activeProject: Readonly<
    Ref<{ readonly [x: string]: Readonly<unknown> } | undefined>
  >;
  setActiveProject: (project?: Record<string, unknown>) => void;
}

export interface StoredCadastre {
  key: string;
  status: string;
  position?: number[];
}

export interface CadastreStage {
  storedCadastres: Readonly<Ref<readonly StoredCadastre[]>>;
  reload: () => Promise<void>;
}

export const ActiveProjectKey: InjectionKey<ProjectStage> =
  Symbol('ActiveProject');
export const DefaultActiveProject: Readonly<ProjectStage> = {
  activeProject: ref(undefined),
  setActiveProject: () => {
    logger.error('No project injected by app.');
  },
};
export const StoredCadastreKey: InjectionKey<CadastreStage> =
  Symbol('StoredCadastre');
export const DefaultStoredCadastre: Readonly<CadastreStage> = {
  storedCadastres: ref<readonly StoredCadastre[]>([]),
  reload: () => Promise.resolve(),
};

export default {
  ActiveProjectKey,
  DefaultActiveProject,
  StoredCadastreKey,
  DefaultStoredCadastre,
};
