import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "tooltip",
    role: "tooltip"
  }, [
    (_ctx.arrow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "arrow",
          id: _ctx.arrowId,
          "data-popper-arrow": ""
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 8, _hoisted_1))
}