import {
  RealtyCadastreItem,
  RealtyFeature,
  RealtyCadastreExtent,
  RealtyGeoJSON,
  RealtyMunicipality,
  RealtyType,
  RealtyDate,
  RealtyCompany,
  RealtyLink,
} from '../RealtyTypes';
import CadastreIdent from './CadastreIdent';

export default class Cadastre implements RealtyCadastreItem {
  public ident: CadastreIdent;

  public key: string;

  public position: RealtyFeature;

  public extent: RealtyCadastreExtent;

  public geojson: RealtyGeoJSON;

  public municipality: RealtyMunicipality;

  public isSectioned: boolean;

  public isHistoricalRegisteredLand: boolean;

  public isHistoricalTechnicalRegisteredLand: boolean;

  public isRegisteredLand: boolean;

  public isTechnicalRegisteredLand: boolean;

  public propertyType: RealtyType;

  public establishedDate: RealtyDate;

  public unitName: string;

  public specifiedArea: number;

  public areaSource: RealtyType;

  public unregisteredFarmCommonsArea: number;

  public areaSourceForUnregisteredFarmCommons: RealtyType;

  public disputedPlotArea: number;

  public areaSourceForDisputedPlot: RealtyType;

  public sharedArea: number;

  public areaSourceForSharedArea: RealtyType;

  public areaMainCadastre: number;

  public areaSourceForMainCadastre: RealtyType;

  public landTaxation: number;

  public pointHitch: boolean;

  public newRegistration: boolean;

  public agriculturalCadastre: boolean;

  public agriculture: {
    updatedDate: RealtyDate;
    agriculturalArea: number;
    otherArea: number;
    forestArea: number;
    fullGrownArea: number;
    cultivatedArea: number;
    pastureArea: number;
    companies: RealtyCompany[];
  };

  public industryGroup: RealtyType;

  public expired: boolean;

  public hasActiveLeasedLand: boolean;

  public hasNotedComplaint: boolean;

  public hasRegisteredLandAcquisition: boolean;

  public hasRegisteredLandConsolidationRequired: boolean;

  public hasOldCadastre: boolean;

  public includedInTotalRealEstate: boolean;

  public hasSingleHeritage: boolean;

  public hasSoilContamination: boolean;

  public hasHousing: boolean;

  public hasHolidayHome: boolean;

  public zeroConcession: boolean;

  public zeroConcessionMessage: string;

  public maxCadastreLevel: RealtyType;

  public numberOfPlots: number;

  public sourceSystem: RealtyType;

  public currentSectioning: {
    fraction: {
      numerator: number;
      denominator: number;
    };
    purpose: RealtyType;
    additionalPart: RealtyType;
  };

  public cityDistrict: {
    key: string;
    name: string;
  };

  public ownedByCooperative: boolean;

  public ownedByCorporation: boolean;

  public ownedByHousingCorporation: boolean;

  public links: {
    self: RealtyLink;
    owners: RealtyLink;
    buildings: RealtyLink;
    soilContaminations: RealtyLink;
    complaints: RealtyLink;
    sectionings: RealtyLink;
    sections: RealtyLink;
    plots: RealtyLink;
    realtyEstateUnits: RealtyLink;
    conveyances: RealtyLink;
    addresses: RealtyLink;
    units: RealtyLink;
    shares: RealtyLink;
    singleHeritages: RealtyLink;
    ownedByOwnerships: RealtyLink;
    ownershipsOwningThisCadastre: RealtyLink;
    ownedOwnerships: RealtyLink;
    ownershipsOwnedByThisCadastre: RealtyLink;
    unregisteredOwnershipsOwnedByThisCadastre: RealtyLink;
    unregisteredOwnershipsOwningThisCadastre: RealtyLink;
    affairs: RealtyLink;
    cadastrePDF: RealtyLink;
    cadastreWithPrivilegesPDF: RealtyLink;
    historicalCadastrePDF: RealtyLink;
    oldCadastrePDF: RealtyLink;
    technicalPDF: RealtyLink;
    simpleTechnicalPDF: RealtyLink;
    mapPDF: RealtyLink;
    simpleMapPDF: RealtyLink;
    mainCadastres: RealtyLink;
    liensCalculation: RealtyLink;
    housingValue: RealtyLink;
    surroundingHousingValues: RealtyLink;
    presentationAddress: RealtyLink;
    landRegistry: RealtyLink;
  };

  constructor(item: RealtyCadastreItem) {
    this.ident = new CadastreIdent(item.ident);
    this.key = item.key;
    this.position = item.position;
    this.extent = item.extent;
    this.geojson = item.geojson;
    this.municipality = item.municipality;
    this.isSectioned = item.isSectioned;
    this.isHistoricalRegisteredLand = item.isHistoricalRegisteredLand;
    this.isHistoricalTechnicalRegisteredLand =
      item.isHistoricalTechnicalRegisteredLand;
    this.isRegisteredLand = item.isRegisteredLand;
    this.isTechnicalRegisteredLand = item.isTechnicalRegisteredLand;
    this.propertyType = item.propertyType;
    this.establishedDate = item.establishedDate;
    this.unitName = item.unitName;
    this.specifiedArea = item.specifiedArea;
    this.areaSource = item.areaSource;
    this.unregisteredFarmCommonsArea = item.unregisteredFarmCommonsArea;
    this.areaSourceForUnregisteredFarmCommons =
      item.areaSourceForUnregisteredFarmCommons;
    this.disputedPlotArea = item.disputedPlotArea;
    this.areaSourceForDisputedPlot = item.areaSourceForDisputedPlot;
    this.sharedArea = item.sharedArea;
    this.areaSourceForSharedArea = item.areaSourceForSharedArea;
    this.areaMainCadastre = item.areaMainCadastre;
    this.areaSourceForMainCadastre = item.areaSourceForMainCadastre;
    this.landTaxation = item.landTaxation;
    this.pointHitch = item.pointHitch;
    this.newRegistration = item.newRegistration;
    this.agriculturalCadastre = item.agriculturalCadastre;
    this.agriculture = item.agriculture;
    this.industryGroup = item.industryGroup;
    this.expired = item.expired;
    this.hasActiveLeasedLand = item.hasActiveLeasedLand;
    this.hasNotedComplaint = item.hasNotedComplaint;
    this.hasRegisteredLandAcquisition = item.hasRegisteredLandAcquisition;
    this.hasRegisteredLandConsolidationRequired =
      item.hasRegisteredLandConsolidationRequired;
    this.hasOldCadastre = item.hasOldCadastre;
    this.includedInTotalRealEstate = item.includedInTotalRealEstate;
    this.hasSingleHeritage = item.hasSingleHeritage;
    this.hasSoilContamination = item.hasSoilContamination;
    this.hasHousing = item.hasHousing;
    this.hasHolidayHome = item.hasHolidayHome;
    this.zeroConcession = item.zeroConcession;
    this.zeroConcessionMessage = item.zeroConcessionMessage;
    this.maxCadastreLevel = item.maxCadastreLevel;
    this.numberOfPlots = item.numberOfPlots;
    this.sourceSystem = item.sourceSystem;
    this.currentSectioning = item.currentSectioning;
    this.cityDistrict = item.cityDistrict;
    this.ownedByCooperative = item.ownedByCooperative;
    this.ownedByCorporation = item.ownedByCorporation;
    this.ownedByHousingCorporation = item.ownedByHousingCorporation;
    this.links = item.links;
  }

  public isEierlosOrVannteig(): boolean {
    return this.isEierlos() || this.isVannteig();
  }

  public isVannteig(): boolean {
    return this.ident.cadastralUnitNumber === 0 && this.ident.unitNumber === 1;
  }

  public isEierlos(): boolean {
    return this.ident.cadastralUnitNumber === 0 && this.ident.unitNumber === 0;
  }

  public getCadastreIdent(): CadastreIdent {
    return this.ident;
  }

  public matrikkelText(): string {
    return this.getCadastreIdent().matrikkelText();
  }
}
