
import { defineComponent, watch, onUnmounted, ref } from 'vue';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import Building from '@/data/wrapper/Building';
import TextUtil from '@/utilities/TextUtil';
import PanelBackButton from '@/components/PanelSystem/PanelBackButton.vue';
import { useProjectStore } from '@/store/project';
import Realty from '@/data/Realty';
import { AuxBanner } from '@ambita/design-system';
import PanelSpinner from '@/components/PanelSystem/PanelSpinner.vue';
import PanelTitle from '@/components/PanelSystem/PanelTitle.vue';
import PanelYesNoLabel from '@/components/PanelSystem/PanelYesNoLabel.vue';
import PanelGroup from '@/components/PanelSystem/PanelGroup.vue';

export default defineComponent({
  name: 'BuildingPanel',
  components: {
    PanelRow,
    PanelColumn,
    PanelBackButton,
    AuxBanner,
    PanelSpinner,
    PanelTitle,
    PanelYesNoLabel,
    PanelGroup,
  },
  inheritAttrs: false,
  props: {
    cadastreKey: {
      type: String,
      required: true,
    },
    buildingKey: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const projectStore = useProjectStore();
    const NumberFormat = new Intl.NumberFormat('nb-NO');

    const building = ref<Building | null>(null);
    const isLoading = ref(false);
    const errorMessage = ref<string | null>(null);

    const getBuilding = () => {
      if (props.buildingKey) {
        isLoading.value = true;
        errorMessage.value = null;

        Realty.getBuilding(props.buildingKey as string)
          .then((result) => {
            building.value = new Building(result);
            isLoading.value = false;
          })
          .catch(() => {
            errorMessage.value = `Klarte ikke hente informasjon om bygning ${props.buildingKey}`;
          });
      }
    };

    getBuilding();

    watch(() => props.buildingKey, getBuilding);
    watch(
      () => building,
      () => {
        if (building.value?.position?.geometry?.coordinates) {
          const lat = building.value?.position.geometry.coordinates[1];
          const lng = building.value?.position.geometry.coordinates[0];

          projectStore.movePointer(lat, lng);
        }
      }
    );
    onUnmounted(() => {
      projectStore.removePointer();
    });

    return {
      NumberFormat,
      building,
      isLoading,
      errorMessage,
    };
  },
  computed: {
    builtUpArea(): number {
      if (this.building === null) {
        return 0;
      }

      return this.building.getBuiltUpArea();
    },
    buildingType(): string {
      if (
        this.building === null ||
        !this.building.typeOfBuilding ||
        !this.building.typeOfBuilding.text
      ) {
        return 'Ukjent bygning';
      }

      return new TextUtil(this.building.typeOfBuilding.text).uCaseFirst();
    },
  },
});
