import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "data-loader" }
const _hoisted_2 = {
  key: 1,
  class: "data-loader-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aux_spinner = _resolveComponent("aux-spinner")!
  const _component_arrow_clockwise_icon = _resolveComponent("arrow-clockwise-icon")!
  const _component_aux_definition = _resolveComponent("aux-definition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_aux_spinner, {
          key: 0,
          size: "medium"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Laster ")
          ])),
          _: 1
        }))
      : (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_aux_definition, { label: _ctx.error }, {
              default: _withCtx(() => [
                _createVNode(_component_arrow_clockwise_icon, {
                  width: "26",
                  height: "26",
                  fill: "#000000",
                  onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('load'))
                })
              ]),
              _: 1
            }, 8, ["label"])
          ]))
        : _renderSlot(_ctx.$slots, "default", { key: 2 }, undefined, true)
  ]))
}