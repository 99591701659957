
import { defineComponent } from 'vue';
import { AuxSpinner, AuxDefinition } from '@ambita/design-system';
import ArrowClockwiseIcon from '@icons/bold/arrow-clockwise-bold.svg';

export default defineComponent({
  name: 'DataLoader',
  components: {
    AuxSpinner,
    AuxDefinition,
    ArrowClockwiseIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: null,
    },
  },
  emits: ['load'],
});
