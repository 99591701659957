
import { defineComponent, PropType, ref } from 'vue';
import Cadastre from '@/data/wrapper/Cadastre';
import { useProjectStore } from '@/store/project';
import { RealtyCadastreItem } from '@/data/RealtyTypes';
import Realty from '@/data/Realty';
import ListCollapsibleBlock from './Blocks/ListCollapsibleBlock.vue';
import List from './Blocks/List.vue';
import ListItem from './Blocks/ListItem.vue';

export default defineComponent({
  name: 'SectionsList',
  components: {
    ListCollapsibleBlock,
    List,
    ListItem,
  },
  props: {
    cadastre: {
      type: Object as PropType<Cadastre>,
      required: true,
    },
  },
  setup(props) {
    const projectStore = useProjectStore();
    const sections = ref<RealtyCadastreItem[]>([]);
    const loadStatus = ref('loading');

    Realty.getSections(props.cadastre.key)
      .then((response) => {
        sections.value = response.items;
        loadStatus.value = 'success';
      })
      .catch(() => {
        loadStatus.value = 'error';
      });

    return {
      projectStore,
      sections,
      loadStatus,
    };
  },
});
