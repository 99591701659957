
import { defineComponent, ref, computed, watch } from 'vue';
import { formatNumber } from '@ambita/design-system';
import HjemlaLogo from '@/assets/img/HjemlaLogoSVG.svg';
import CaretDownIcon from '@icons/regular/caret-down.svg';
import { useRouter } from 'vue-router';
import { useProjectStore } from '@/store/project';
import Realty from '@/data/Realty';
import { useMapStore } from '@/store/map';

export default defineComponent({
  setup() {
    const router = useRouter();
    const projectStore = useProjectStore();
    const mapStore = useMapStore();

    const estimate = ref<number | null>(null);

    const estimateFormatted = computed(() =>
      formatNumber(estimate.value, { decimals: 0 })
    );

    const hjemlaUrl = computed(() => {
      const hjemlaBaseURL = 'https://www.hjemla.no/boligkart';
      const point = projectStore.getCurrentCadastresCenterPointsLatLng;

      if (point !== null) {
        return `${hjemlaBaseURL}?z=18&lng=${point.lat}&lat=${point.lng}`;
      }
      return hjemlaBaseURL;
    });

    watch(
      () => router.currentRoute.value.params.cadastreKey,
      (cadastreKey) => {
        estimate.value = null;
        if (!cadastreKey) {
          return;
        }

        Realty.getEstimatedValue(cadastreKey as string).then(
          (response: {
            item: { calculatedValue: number; isCalculable: boolean };
          }) => {
            if (response.item?.isCalculable) {
              estimate.value = response.item.calculatedValue;
            }
          }
        );
      },
      { immediate: true }
    );

    return {
      hjemlaUrl,
      estimate,
      estimateFormatted,
    };
  },
  components: {
    HjemlaLogo,
    CaretDownIcon,
  },
});
