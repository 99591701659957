
import { defineComponent, onMounted, ref } from 'vue';
import Extent from '@arcgis/core/geometry/Extent';
import { DemographyDataFields } from '@/data/Demography';
import Tabs from '@/components/helper/Tabs.vue';
import Tab from '@/components/helper/Tab.vue';
import Demography from '@/components/PropertyDetailsPanel/Demography/Demography.vue';
import PanelBackButton from '@/components/PanelSystem/PanelBackButton.vue';
import PanelSpinner from '@/components/PanelSystem/PanelSpinner.vue';
import { useMapStore } from '@/store/map';
import { useProjectStore } from '@/store/project';

export default defineComponent({
  name: 'DemographyExtentPanel',
  components: {
    PanelSpinner,
    PanelBackButton,
    Tabs,
    Tab,
    Demography,
  },
  setup() {
    const mapStore = useMapStore();
    const projectStore = useProjectStore();
    const isLoading = ref(true);
    const geometry = ref({});

    onMounted(() => {
      if (
        !mapStore.extent.xmin ||
        !mapStore.extent.xmax ||
        !mapStore.extent.ymin ||
        !mapStore.extent.ymax
      ) {
        return;
      }

      geometry.value = {
        xmin: mapStore.extent.xmin,
        xmax: mapStore.extent.xmax,
        ymin: mapStore.extent.ymin,
        ymax: mapStore.extent.ymax,
      } as Extent;

      isLoading.value = false;
    });

    mapStore.$onAction(({ name, after }) => {
      if (name === 'setExtent') {
        isLoading.value = true;

        after(() => {
          geometry.value = {
            xmin: mapStore.extent.xmin,
            xmax: mapStore.extent.xmax,
            ymin: mapStore.extent.ymin,
            ymax: mapStore.extent.ymax,
          } as Extent;

          isLoading.value = false;
        });
      }
    });

    return {
      geometry,
      types: DemographyDataFields,
      projectStore,
      isLoading,
    };
  },
});
