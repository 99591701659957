import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBackButton = _resolveComponent("PanelBackButton")!
  const _component_PanelSpinner = _resolveComponent("PanelSpinner")!
  const _component_AuxBanner = _resolveComponent("AuxBanner")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_arrow_square_out_icon = _resolveComponent("arrow-square-out-icon")!
  const _component_PanelGroup = _resolveComponent("PanelGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PanelBackButton, {
      to: _ctx.$route.path.replace(`/kulturminner/${_ctx.heritageKey}`, ''),
      label: _ctx.cadastreKey
    }, null, 8, ["to", "label"]),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_PanelSpinner, { key: 0 }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Henter informasjon om kulturminnet ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_AuxBanner, {
          key: 1,
          class: "error-banner",
          type: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.heritage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_PanelGroup, { title: "Kulturminne" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_row, { columns: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Kategori" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.heritageType), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_panel_column, { title: "Funn ID" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.heritage.key), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Kategorikode" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.heritage.categoryCode.text.toLowerCase()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Vernetype" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.heritage.preservationTypeCode.text.toLowerCase()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Ekstern kilde" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: _ctx.heritage.links.singleHeritageWebpage.href,
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }, [
                        _cache[1] || (_cache[1] = _createTextVNode(" Les mer hos Riksantikvaren ")),
                        _createVNode(_component_arrow_square_out_icon, {
                          width: "18",
                          height: "18"
                        })
                      ], 8, _hoisted_1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_PanelGroup, { title: "Hendelsesdatoer" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_row, { columns: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Sist oppdatert" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.heritage.lastUpdatedDate.ddmmyyyy), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_panel_column, { title: "Registreringsdato" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.heritage.registeredDate.ddmmyyyy), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}