import { DriveStep } from 'driver.js';

const steps: DriveStep[] = [
  {
    element: 'generate-report',
    popover: {
      title: 'Nyhet',
      description:
        '<ul>' +
        '<li>Du kan bruke grensesnittet mens rapporten genereres i bakgrunn.</li>' +
        '<li>Du blir varslet når rapporten er klar.</li>' +
        '<li>Vi har gjort forbedringer i utformingen av rapporten.</li>' +
        '<li>Vi har økt kvaliteten av skjermbilder.</li>' +
        '<li>Du får nå et skjermbilde av eiendommen i 3D.</li>' +
        '<li>Vi har lagt ved beskrivelse og tegnforklaring under kartlag skjermbilder.</li>' +
        '</ul>',
      side: 'right',
      align: 'start',
    },
  },
];

export default steps;
