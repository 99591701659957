import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryPropertiesEmptyState = _resolveComponent("SummaryPropertiesEmptyState")!
  const _component_AuxSpinner = _resolveComponent("AuxSpinner")!
  const _component_printer_icon = _resolveComponent("printer-icon")!
  const _component_panel_hr = _resolveComponent("panel-hr")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_SelectedProperties = _resolveComponent("SelectedProperties")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.projectStore.selectedCadastres.length === 0 && _ctx.projectStore.projectLoaded
    )
      ? (_openBlock(), _createBlock(_component_SummaryPropertiesEmptyState, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.projectStore.projectLoaded)
      ? (_openBlock(), _createBlock(_component_AuxSpinner, { key: 1 }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Laster eiendommer")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (
      _ctx.projectStore.selectedCadastres.length > 0 && _ctx.projectStore.projectLoaded
    )
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(_ctx.panelStyle.panel)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.panelStyle.header)
          }, "Oppsummert", 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.panelStyle.report)
          }, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.$emit('generateReport'), ["prevent"]))
            }, [
              _createVNode(_component_printer_icon, {
                width: "32",
                height: "32"
              }),
              _cache[2] || (_cache[2] = _createTextVNode("Skriv ut / del rapport "))
            ])
          ], 2),
          _createVNode(_component_panel_hr),
          _createVNode(_component_panel_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Total areal" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(`${_ctx.NumberFormat.format(_ctx.projectStore.getTotalSelectedArea)} m²`), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_panel_hr),
          _createVNode(_component_SelectedProperties)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}