
import { defineComponent, reactive } from 'vue';
import { AuxModal } from '@ambita/design-system';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import XIcon from '@icons/regular/x.svg';
import CaretRightIcon from '@icons/regular/caret-right.svg';
import { useProjectStore } from '@/store/project';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SelectedProperties',
  components: {
    AuxModal,
    XIcon,
    CaretRightIcon,
    PanelRow,
    PanelColumn,
  },
  setup() {
    const projectStore = useProjectStore();
    const { getCadastres } = storeToRefs(projectStore);
    const state = reactive({
      showDeleteModal: false,
    });

    const toggleDeletePropertiesDialog = () => {
      state.showDeleteModal = !state.showDeleteModal;
    };

    const removeAllProperties = () => {
      projectStore.removeAllProperties(true);
      state.showDeleteModal = false;
    };

    const removeProperty = (cadastreKey: string) => {
      projectStore.removeProperty(cadastreKey);
      projectStore.setHoverProperty(null);
    };

    const handleMouseHover = (cadastre: string): void => {
      projectStore.setHoverProperty(cadastre);
    };

    const handleMouseOut = (): void => {
      projectStore.setHoverProperty(null);
    };

    const navigateToProperty = (cadastreKey: string): void => {
      projectStore.setHoverProperty(null);
      projectStore.setCurrentProperty(cadastreKey);
    };

    return {
      state,
      projectStore,
      handleMouseHover,
      handleMouseOut,
      navigateToProperty,
      removeAllProperties,
      removeProperty,
      toggleDeletePropertiesDialog,
      getCadastres,
      modalActions: [
        {
          label: 'Fjern alle lagrede tomter',
          emit: 'delete',
          primary: true,
        },
        {
          label: 'Avbryt',
          emit: 'close-modal',
        },
      ],
    };
  },
});
