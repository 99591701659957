/* eslint-disable max-classes-per-file */
import * as RealtyTypes from '@/data/RealtyTypes';

/* eslint-disable import/no-cycle */
// @TODO: disable dependency cycles
import PropFinderMap from '@/components/Map/PropfinderMap';
import Building from './Building';
import Polygon from './Polygon';
import { PropertyData } from '../Realty';
/* eslint-enable import/no-cycle */

import Heritage from './Heritage';
import SoilContamination from './SoilContamination';
import Address from './Address';
import Cadastre from './Cadastre';
import Ownership from './Ownership';

interface RealtyType {
  key: string;
}

class RealtyFinder<Type extends RealtyType> {
  // eslint-disable-next-line class-methods-use-this
  public find(objects: Type[] | null, key: string): Type | null {
    if (objects === null || objects.length === 0) {
      return null;
    }

    const results = objects.filter((object) => object.key === key);

    if (results) {
      return results[0];
    }

    return null;
  }
}

export default class Property implements PropertyData {
  public polygon: Polygon | null;

  public ownerships: Ownership[];

  public address: Address | null;

  public buildings: Building[] | null;

  public latestConveyance: RealtyTypes.RealtyConveyances | null;

  public heritages: Heritage[] | null;

  public soilContaminations: SoilContamination[] | null;

  public cadastre: Cadastre;

  constructor(prop: PropertyData) {
    this.polygon = prop.polygon;
    this.ownerships = [];
    this.address =
      prop.address && prop.address !== null ? new Address(prop.address) : null;
    this.buildings = null;
    this.latestConveyance = prop.latestConveyance;
    this.heritages = prop.heritages;
    this.soilContaminations = prop.soilContaminations;
    this.cadastre = new Cadastre(prop.cadastre);

    if (this.polygon && (!this.polygon.type || !this.polygon.coordinates)) {
      this.polygon = null;
    }

    const buildings = prop.buildings?.map((building) => new Building(building));
    const ownerships = prop.ownerships?.map(
      (ownership) => new Ownership(ownership)
    );

    if (buildings) {
      this.buildings = buildings;
    }

    if (ownerships) {
      this.ownerships = ownerships;
    }
  }

  public isEierlosOrVannteig(): boolean {
    return this.getCadastre().isEierlosOrVannteig();
  }

  public getBuilding(key: string): Building | null {
    return new RealtyFinder<Building>().find(this.buildings, key);
  }

  public getBuildings(): Building[] {
    if (this.buildings === null) {
      return [];
    }

    return this.buildings;
  }

  public buildingArea(): number {
    return this.getBuildings()
      .map((building) => building.getBuiltUpArea())
      .filter((area) => area > 0)
      .reduce((total, area) => total + area, 0);
  }

  public getHeritage(key: string): Heritage | null {
    return new RealtyFinder<Heritage>().find(this.heritages, key);
  }

  public getSoilContamination(key: string): SoilContamination | null {
    return new RealtyFinder<SoilContamination>().find(
      this.soilContaminations,
      key
    );
  }

  public getAddress(): Address | null {
    return this.address;
  }

  public getCadastre(): Cadastre {
    return this.cadastre;
  }

  public getPolygon(): Polygon | null {
    return this.polygon;
  }

  public getOwnerships(): Ownership[] {
    if (this.ownerships === null) {
      return [];
    }

    return this.ownerships;
  }

  public isNotWithinMap(propFinderMap: PropFinderMap): boolean {
    if (this.polygon === null) {
      return true;
    }

    return this.polygon.isNotWithinMap(propFinderMap);
  }

  public isWithinMap(propFinderMap: PropFinderMap): boolean {
    if (this.polygon === null) {
      return false;
    }

    return this.polygon.isWithinMap(propFinderMap);
  }

  public addressText(): string {
    const address = this.getAddress();

    if (address === null) {
      return this.matrikkelText();
    }

    return address.addressText();
  }

  public matrikkelText(): string {
    return this.getCadastre().matrikkelText();
  }

  public cadastreKey(): string {
    return this.getCadastre().key;
  }

  /**
   * Area name of the property:  City district (bydel) or municipality name
   * @returns {string|*}
   */
  public areaName(): string {
    const cadastre = this.getCadastre();

    if (cadastre.isEierlosOrVannteig()) {
      return '';
    }

    if (
      Object.prototype.hasOwnProperty.call(cadastre, 'cityDistrict') &&
      cadastre.cityDistrict
    ) {
      return cadastre.cityDistrict.name;
    }

    return cadastre.municipality.municipalityName;
  }
}
