
import { defineComponent, PropType } from 'vue';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import SelectedProperties from '@/components/PropertyDetailsPanel/SelectedProperties/SelectedProperties.vue';
import PropertySelection from '@/data/wrapper/PropertySelection';
import Polygon from '@/data/wrapper/Polygon';
import PrinterIcon from '@icons/regular/printer.svg';
import { useProjectStore } from '@/store/project';
import PanelHr from '@/components/PanelSystem/PanelHr.vue';
import SummaryPropertiesEmptyState from '@/components/PropertyDetailsPanel/SummaryComponent/SummaryPropertiesEmptyState.vue';
import { AuxSpinner } from '@ambita/design-system';

export default defineComponent({
  name: 'Summary',
  inheritAttrs: false,
  components: {
    PanelHr,
    PanelRow,
    PanelColumn,
    SelectedProperties,
    PrinterIcon,
    SummaryPropertiesEmptyState,
    AuxSpinner,
  },
  props: {
    selectedProperties: {
      type: Object as PropType<PropertySelection> | null,
      default: null,
    },
  },
  emits: [
    'setHighlightedPolygon',
    'clearHighlightedPolygon',
    'removeSelectedProperties',
    'removeProperty',
    'moveToSelectedProperties',
    'generateReport',
  ],
  setup() {
    const projectStore = useProjectStore();

    const NumberFormat = new Intl.NumberFormat(['nb-NO']);

    return {
      projectStore,
      NumberFormat,
    };
  },
  mounted() {
    this.$emit('moveToSelectedProperties');
  },
  methods: {
    setHighlightedPolygon(pol: Polygon): void {
      this.$emit('setHighlightedPolygon', pol);
    },
    clearHighlightedPolygon(): void {
      this.$emit('clearHighlightedPolygon');
    },
    removeSelectedProperties(): void {
      this.$emit('removeSelectedProperties');
    },
    removeProperty(key: string): void {
      this.$emit('removeProperty', key);
    },
  },
});
