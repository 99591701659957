
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SEO',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    canonical: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      base_url: process.env.BASE_URL,
    };
  },
  computed: {
    pageTitle(): string {
      let pageTitle = String(process.env.VUE_APP_PAGE_TITLE);

      if (this.title && this.title.length) {
        pageTitle = `${this.title} | ${pageTitle}`;
      }

      return pageTitle;
    },
  },
});
