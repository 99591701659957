import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tabs" }

import { h, provide, ref, toRefs, useSlots, VNode } from 'vue';
import { Props as TabProps } from './Tab.vue';

export interface Props {
  defaultIndex?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Tabs',
  props: {
    defaultIndex: { default: 0 }
  },
  emits: ["change"],
  setup(__props: any, { emit: __emit }) {

const activeTab = ref('');

const props = __props;

const emit = __emit;

const slots = useSlots();
const { defaultIndex } = toRefs(props);
let lastDefaultIndex = defaultIndex.value;

provide('activeTab', activeTab);

const buttons = () => {
  const defaultSlot = slots.default && slots.default();
  const li: Array<VNode> = [];

  if (!defaultSlot) {
    return h('p', 'Tabs missing slots');
  }

  defaultSlot.forEach((child) => {
    if (!Object.prototype.hasOwnProperty.call(child, 'type')) {
      return;
    }

    if (!(child.type instanceof Object)) {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(child.type, 'name')) {
      return;
    }

    if ((child.type as unknown as Record<string, string>).name !== 'Tab') {
      return;
    }

    const childProps = child.props as TabProps;

    if (li.length === defaultIndex.value) {
      const tabUnset = activeTab.value.length === 0;
      const idxChange = lastDefaultIndex !== defaultIndex.value;

      if (tabUnset || idxChange) {
        activeTab.value = childProps.title;
        lastDefaultIndex = defaultIndex.value;
      }
    }

    li.push(
      h(
        'li',
        {
          class: 'tabs-list__header',
          'aria-disabled': childProps.disabled,
          'aria-active': activeTab.value === childProps.title,
          onClick: (e: MouseEvent): void => {
            e.preventDefault();

            if (!childProps.disabled && activeTab.value !== childProps.title) {
              activeTab.value = childProps.title;
              emit('change', childProps.title);
            }
          },
        },
        childProps.title
      )
    );
  });

  return h(
    'ul',
    {
      class: 'tabs-list',
    },
    li
  );
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(buttons),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})