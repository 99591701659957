export default class TextUtil {
  public string: string;

  constructor(string?: string) {
    this.string = string || '';
  }

  public uCaseFirst(): string {
    if (this.string) {
      return (
        this.string.charAt(0).toUpperCase() + this.string.slice(1).toLowerCase()
      );
    }

    return '';
  }

  public numberCount(): number {
    if (!this.string) {
      return 0;
    }

    const pattern = /\d+/g;
    const match = this.string.match(pattern);

    if (!match) {
      return 0;
    }

    return match.length;
  }

  public nonNumericCount(): number {
    if (!this.string) {
      return 0;
    }

    let string = this.string.replace(' ', '');
    string = string.replace('.', '');

    const pattern = /[^0-9]/g;
    const match = string.match(pattern);

    if (!match) {
      return 0;
    }

    return match.length;
  }

  public ifNull(valueIfNull: string): string {
    if (!this.string) {
      return valueIfNull;
    }

    return this.string;
  }
}
