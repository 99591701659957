import { DriveStep } from 'driver.js';

const tourSteps: DriveStep[] = [
  {
    element: 'search',
    popover: {
      title: 'Søk etter tomt eller område',
      description:
        'Hvis du vet hvor du vil, kommer du raskt til riktig sted ved å søke på adresse, eller matrikkel',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'filter',
    popover: {
      title: 'Se kun tomter som er aktuelle',
      description:
        'Finn en tomt som passer dine krav ved å definere hva du ser etter i filteret',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'map-2D',
    popover: {
      title: 'Se resultatene og grav dypere på en enkelt eiendom',
      description: 'Trykk på en tomt for å vise mer informasjon om tomten',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'map-layer-picker',
    popover: {
      title: 'Vis kartlag',
      description:
        'Se hvilke interessepunkter, risikoelementer og andre faktorer som berører tomten eller området rundt',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'toggle-3d',
    popover: {
      title: 'Se på utsikten og solforholdene i 3D',
      description:
        'Vurder tomtens potensiale i 3D. Du kan fjerne eksisterende bygg, tegne nytt eller importerte en ferdig tegnet 3D-modell',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'demography-toggle',
    popover: {
      title: 'Demografianalyse',
      description:
        'Se alder, inntekt, sivilstand, formue m.m. for utsnittet i kartet',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'tour-trigger',
    popover: {
      title: 'Få hjelp når du trenger det',
      description:
        'Ønsker du en ny intro kan du klikke på infoikonet og bli guidet gjennom relevante introer på nytt',
      side: 'right',
      align: 'start',
    },
  },
];

export default tourSteps;
