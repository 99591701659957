import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tab"
}

import { inject, ref, toRefs, computed } from 'vue';

export interface Props {
  title: string;
  disabled?: boolean;
}


const __default__ = {
  name: 'Tab',
  inheritAttrs: false,
  customOptions: {},
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    title: { default: 'Tab' },
    disabled: { type: Boolean, default: false }
  },
  setup(__props: any) {

/**
 * eslint does not understand the necessary script separations, and crashes if
 * <script> tags are reorganized.
 */
// eslint-disable-next-line import/first
const props = __props;

const { title, disabled } = toRefs(props);

const activeTab = inject('activeTab', ref(title.value));

const active = computed(
  () => !disabled.value && title.value === activeTab.value
);

return (_ctx: any,_cache: any) => {
  return (active.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}
}

})