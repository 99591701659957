
import { defineComponent, PropType, ref, onBeforeUnmount } from 'vue';
import Cadastre from '@/data/wrapper/Cadastre';
import DownloadLink from './Blocks/DownloadLink.vue';

export default defineComponent({
  name: 'Documents',
  components: {
    DownloadLink,
  },
  props: {
    cadastre: {
      type: Object as PropType<Cadastre>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isActive = ref(false);

    const getReportUrl = (reportName: string) => {
      return `${process.env.VUE_APP_AMBITA_API}/realty/v1/cadastres/${props.cadastre.key}/${reportName}/`;
    };
    const getOldCadastreReportUrl = () => {
      return `${process.env.VUE_APP_AMBITA_API}/realty/v1/pdfs/oldcadastre/${props.cadastre.key}`;
    };
    const getReportFileName = (reportName: string) => {
      return `${reportName}-${props.cadastre.key}`;
    };

    const activateList = () => {
      isActive.value = !isActive.value;
    };

    onBeforeUnmount(() => {
      emit('closeProductsModal');
    });

    return {
      getReportUrl,
      getOldCadastreReportUrl,
      getReportFileName,
      activateList,
      isActive,
    };
  },
});
