import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBackButton = _resolveComponent("PanelBackButton")!
  const _component_PanelSpinner = _resolveComponent("PanelSpinner")!
  const _component_AuxBanner = _resolveComponent("AuxBanner")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_PanelTitle = _resolveComponent("PanelTitle")!
  const _component_PanelYesNoLabel = _resolveComponent("PanelYesNoLabel")!
  const _component_PanelGroup = _resolveComponent("PanelGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PanelBackButton, {
      to: _ctx.$route.path.replace(`/bygninger/${_ctx.buildingKey}`, ''),
      label: _ctx.cadastreKey as string
    }, null, 8, ["to", "label"]),
    (_ctx.isLoading && !_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_PanelSpinner, { key: 0 }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Henter informasjon om bygningen ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_AuxBanner, {
          key: 1,
          class: "error-banner",
          type: "error"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.building !== null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_panel_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Bygningsnummer" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.building.key), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_panel_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Bygningstype" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buildingType), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_panel_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Næringsgruppe" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.building.industryGroupText()), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_PanelTitle, { title: "Areal" }),
          _createVNode(_component_panel_row, { columns: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Bruksareal (BRA)" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.building.totalFloorSpace
            ? `${_ctx.NumberFormat.format(_ctx.building.totalFloorSpace)} m²`
            : 'Ikke registrert'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_panel_column, { title: "Grunnflateareal" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.builtUpArea > 0
            ? `${_ctx.NumberFormat.format(_ctx.builtUpArea)} m²`
            : 'Ikke registrert'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_panel_row, { columns: "2" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Etasjer" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.building.numberOfFloors), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_panel_column, { title: "Heis" }, {
                default: _withCtx(() => [
                  _createVNode(_component_PanelYesNoLabel, {
                    condition: _ctx.building.hasElevator
                  }, null, 8, ["condition"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_panel_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_panel_column, { title: "Boenheter" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.building.numberOfUnits), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_panel_row),
          _createVNode(_component_PanelGroup, { title: "Bygningsstatus" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Bygningsstatus" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.building.buildingStatusText()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, { columns: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Ferdigattest" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PanelYesNoLabel, {
                        condition: _ctx.building.hasFerdigattest()
                      }, null, 8, ["condition"])
                    ]),
                    _: 1
                  }),
                  (_ctx.building.usedDate)
                    ? (_openBlock(), _createBlock(_component_panel_column, {
                        key: 0,
                        title: "Tatt i bruk"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.building.usedDate.ddmmyyyy), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, { columns: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Rammetillatelse" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PanelYesNoLabel, {
                        condition: _ctx.building.hasRammetillatelse()
                      }, null, 8, ["condition"])
                    ]),
                    _: 1
                  }),
                  (_ctx.building.approvedDate)
                    ? (_openBlock(), _createBlock(_component_panel_column, {
                        key: 0,
                        title: "Rammetillatelse gitt"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.building.approvedDate.ddmmyyyy
              ? _ctx.building.approvedDate.ddmmyyyy
              : 'Ikke registert'), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}