import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "VerticalBarChart" }
const _hoisted_2 = { class: "vertical-bar-chart-label" }
const _hoisted_3 = { class: "vertical-bar-chart-bar" }
const _hoisted_4 = {
  key: 0,
  class: "vertical-bar-chart-bold"
}
const _hoisted_5 = { class: "vertical-bar-chart-bar" }
const _hoisted_6 = {
  key: 1,
  class: "vertical-bar-chart-bold"
}
const _hoisted_7 = { class: "vertical-bar-chart-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (val, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "vertical-bar-chart",
        key: `bc${index}`
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(val.description), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            style: _normalizeStyle(`width: ${val.barWidth}%;`)
          }, null, 4),
          (_ctx.showNumbers)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
            'vertical-bar-chart-long-label': String(val.number).length > 4,
          })
              }, _toDisplayString(val.number), 3))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({ 'vertical-bar-chart-long-label': val.percent.length > 4 })
              }, _toDisplayString(val.percent), 3))
        ])
      ]))
    }), 128)),
    (_ctx.showNumbers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "vertical-bar-chart-label" }, "0", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", null, _toDisplayString(_ctx.maxNum), 1)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "vertical-bar-chart-label" }, "0%", -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", null, _toDisplayString(_ctx.max) + "%", 1)
          ])
        ]))
  ]))
}