import { LatLngExpression } from 'leaflet';
import {
  RealtyPerson,
  RealtyDate,
  RealtyLocation,
  RealtyType,
  RealtyLink,
} from '../RealtyTypes';

export default class Person implements RealtyPerson {
  public ident: {
    id: 0;
  };

  public key: string;

  public birthday: string;

  public dateOfBirth: RealtyDate;

  public organizationNumber: string;

  public personalNumberType: RealtyType;

  public sex: string;

  public age: number;

  public isNorwegianCitizen: boolean;

  public personalNumber: string;

  public givenName: string;

  public middleName: string;

  public familyName: string;

  public name: string;

  public location: RealtyLocation;

  public businessAddress: string;

  public postalAddress: string;

  public businessType: RealtyType;

  public industrialClassification: RealtyType[];

  public numberMainUnit: number;

  public deletionDate: RealtyDate;

  public foreignAddress: string;

  public dsfState: RealtyType;

  public hasProperty: boolean;

  public links: {
    self: RealtyLink;
    ownedCadastres: RealtyLink;
    ownedShares: RealtyLink;
    residence: RealtyLink;
    cadastreOwnerships: RealtyLink;
    unregisteredCadastreOwnerships: RealtyLink;
    shareOwnerships: RealtyLink;
    customerScore: RealtyLink;
    homeEquity: RealtyLink;
  };

  constructor(item: RealtyPerson) {
    this.ident = item.ident;
    this.key = item.key;
    this.birthday = item.birthday;
    this.dateOfBirth = item.dateOfBirth;
    this.organizationNumber = item.organizationNumber;
    this.personalNumberType = item.personalNumberType;
    this.sex = item.sex;
    this.age = item.age;
    this.isNorwegianCitizen = item.isNorwegianCitizen;
    this.personalNumber = item.personalNumber;
    this.givenName = item.givenName;
    this.middleName = item.middleName;
    this.familyName = item.familyName;
    this.name = item.name;
    this.location = item.location;
    this.businessAddress = item.businessAddress;
    this.postalAddress = item.postalAddress;
    this.businessType = item.businessType;
    this.industrialClassification = item.industrialClassification;
    this.numberMainUnit = item.numberMainUnit;
    this.deletionDate = item.deletionDate;
    this.foreignAddress = item.foreignAddress;
    this.dsfState = item.dsfState;
    this.hasProperty = item.hasProperty;
    this.links = item.links;
  }

  public fullName(): string {
    if (!this.isPhysicalPerson()) {
      return this.name;
    }

    return [this.givenName, this.middleName, this.familyName]
      .filter((namePart) => namePart)
      .join(' ');
  }

  public isPhysicalPerson(): boolean {
    return this.personalNumberType.code === 'F';
  }

  public latLng(): LatLngExpression {
    const [first, second] = this.location.position.geometry.coordinates;

    return {
      lat: second,
      lng: first,
    };
  }

  public locationText(): string {
    if (!this.location) {
      return '';
    }

    if (!this.location.street) {
      return this.location.municipality.municipalityName;
    }

    return this.addressText();
  }

  public addressText(): string {
    const { letter, street, addressNumber, postalPlace } = this.location;
    return [street, addressNumber, letter, postalPlace]
      .filter((part) => part)
      .join(' ');
  }
}
