import { createApp, markRaw } from 'vue';
import {
  coreActions,
  coreStore,
  constants,
} from '@ambita/ambita-components-core';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import logger from './utilities/Logger';
import '@/css/index.scss';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    hasTouchScreen: boolean;
  }
}

const app = createApp(App);
const pinia = createPinia();
app.config.globalProperties.window = window;

// Remove title to enable SEO component to do it's magic.
document.querySelector('head title')?.remove();

const { core: coreConstants } = constants;

/* Function based on Mozilla's mobile device detection recommendations */
function hasTouchScreen(): boolean {
  if (Object.prototype.hasOwnProperty.call(navigator, 'maxTouchPoints')) {
    return navigator.maxTouchPoints > 0;
  }
  if (Object.prototype.hasOwnProperty.call(navigator, 'msMaxTouchPoints')) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (navigator as any).msMaxTouchPoints > 0;
  }
  const mQ =
    Object.prototype.hasOwnProperty.call(window, 'matchMedia') &&
    matchMedia('(pointer:coarse)');

  if (mQ && mQ.media === '(pointer:coarse)') {
    return !!mQ.matches;
  }
  if (Object.prototype.hasOwnProperty.call(window, 'orientation')) {
    return true; // deprecated, but good fallback
  }
  // Only as a last resort, fall back to user agent sniffing
  const UA = navigator.userAgent;
  return (
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
    /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
  );
}

new Promise<void>((resolve) => {
  const onAuthEvent = function authEvent() {
    coreStore.off(coreConstants.USER_CHANGE_EVENT, onAuthEvent);
    coreStore.off(coreConstants.TOKEN_NOT_FOUND_EVENT, onAuthEvent);
    resolve();
  };

  coreStore.on(coreConstants.USER_CHANGE_EVENT, onAuthEvent);
  coreStore.on(coreConstants.TOKEN_NOT_FOUND_EVENT, onAuthEvent);

  coreActions.changeSettings({
    environment: String(process.env.VUE_APP_CORE_ENV),
    currency: ',-',
    currencySymbolPosition: 'suffix',
    dateFormat: 'shortDate',
    clientId: String(process.env.VUE_APP_CLIENT_ID),
  });
})
  .then(() => {
    app.config.globalProperties.hasTouchScreen = hasTouchScreen();
    app.use(router);

    pinia.use(({ store }) => {
      // eslint-disable-next-line no-param-reassign
      store.router = markRaw(router);
    });
    app.use(pinia);

    app.mount('#app');
  })
  .catch((error: Error) => {
    logger.error(error);
  });

if (process.env.NODE_ENV !== 'production') {
  window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
    logger.warn('Unhandled rejection detected.');
    logger.error(promiseRejectionEvent);
    return false;
  });

  window.addEventListener('error', (error) => {
    logger.warn(
      `Unhandled error detected ${error.lineno}@${error.filename} (col: ${error.colno}).`
    );
    logger.error(error);
    return false;
  });
}
const ENVIRONMENT = process.env.NODE_ENV || 'development';
if (['production', 'beta'].includes(ENVIRONMENT)) {
  const {
    VUE_APP_RUM_ID,
    VUE_APP_RUM_ARN,
    VUE_APP_RUM_IDENTITY_POOL_ID,
    VUE_APP_RUM_REGION,
    VUE_APP_RUM_ENDPOINT,
    VUE_APP_PROPFINDER_VERSION,
  } = process.env;

  const listener = (event: Event) => {
    window.removeEventListener('PiwikConsents', listener);
    const e = event as unknown as Record<string, string[]>;

    if (!e.consents || !e.consents.includes('user_feedback')) {
      return;
    }

    try {
      const config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: VUE_APP_RUM_ARN,
        identityPoolId: VUE_APP_RUM_IDENTITY_POOL_ID,
        endpoint: VUE_APP_RUM_ENDPOINT,
        allowCookies: true,
        enableXRay: true,
        telemetries: [
          'performance',
          'errors',
          [
            'http',
            {
              urlsToExclude: [
                /^https:\/\/vector\.services\.geodataonline\.no/gm,
              ],
            },
          ],
        ],
      };

      const APPLICATION_ID = VUE_APP_RUM_ID || '';
      const APPLICATION_VERSION =
        VUE_APP_PROPFINDER_VERSION || 'NO_VERSION_SET';
      const APPLICATION_REGION = VUE_APP_RUM_REGION || 'eu-west-1';

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const awsRum: AwsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  };

  window.addEventListener('PiwikConsents', listener);
}
