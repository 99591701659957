import TextUtil from '@/utilities/TextUtil';
import { LatLngExpression } from 'leaflet';
import {
  RealtyPresentationAddress,
  RealtyAddressIdent,
  RealtyDate,
  RealtyMunicipality,
  RealtyFeature,
  RealtyType,
  RealtyStreet,
  RealtyGeoJSON,
  RealtyLink,
} from '../RealtyTypes';

export default class Address implements RealtyPresentationAddress {
  public ident: RealtyAddressIdent;

  public key: string;

  public updatedTime: RealtyDate;

  public updatedDate?: RealtyDate;

  public addressType: RealtyType;

  public municipality: RealtyMunicipality;

  public position: RealtyFeature;

  public eventStatus: number;

  public alternativeAddress: string;

  public shortName: string;

  public sourceOfAddressName: RealtyType;

  public street: RealtyStreet;

  public geojson: RealtyGeoJSON;

  public hasShare: boolean;

  public hasHousing: boolean;

  public hasHolidayHome: boolean;

  public links: {
    self: RealtyLink;
    cadastres: RealtyLink;
    buildings: RealtyLink;
    units: RealtyLink;
    districts: RealtyLink;
    shares: RealtyLink;
    street: RealtyLink;
  };

  constructor(item: RealtyPresentationAddress) {
    this.ident = item.ident;
    this.key = item.key;
    this.updatedTime = item.updatedTime;
    this.updatedDate = item.updatedDate;
    this.addressType = item.addressType;
    this.municipality = item.municipality;
    this.position = item.position;
    this.eventStatus = item.eventStatus;
    this.alternativeAddress = item.alternativeAddress;
    this.shortName = item.shortName;
    this.sourceOfAddressName = item.sourceOfAddressName;
    this.street = item.street;
    this.geojson = item.geojson;
    this.hasShare = item.hasShare;
    this.hasHousing = item.hasHousing;
    this.hasHolidayHome = item.hasHolidayHome;
    this.links = item.links;
  }

  public addressText(): string {
    if (this.isMatrikkelAddress()) {
      return this.key || 'Ukjent matrikkel';
    }

    let text = this.getStreetName();

    if (this.ident?.addressNumber) {
      text += ` ${this.ident.addressNumber}`;
    }

    if (this.ident?.letter) {
      text += ` ${this.ident.letter}`;
    }

    if (this.street && (this.street.postalCode || this.street.postalPlace)) {
      text += this.getPostalAddress();
    }

    return text;
  }

  private getStreetName(): string {
    if (this.street?.streetName) {
      return this.street.streetName;
    }

    if (this.alternativeAddress) {
      return new TextUtil(this.alternativeAddress).uCaseFirst();
    }

    if (this.shortName) {
      return new TextUtil(this.shortName).uCaseFirst();
    }

    if (this.key) {
      return this.key;
    }

    return 'Ukjent adresse';
  }

  public isStreetAddress(): boolean {
    return this?.street !== null && this.street?.ident !== null;
  }

  public isMatrikkelAddress(): boolean {
    return !this.isStreetAddress();
  }

  public postalPlaceName(): string | boolean {
    if (this.street !== null && this.street.postalPlace !== null) {
      return new TextUtil(this.street.postalPlace).uCaseFirst();
    }

    return false;
  }

  private getPostalAddress(): string {
    if (this.street.postalPlace && this.street.postalCode) {
      return `, ${this.street.postalCode
        .toString()
        .padStart(4, '0')} ${new TextUtil(
        this.street.postalPlace
      ).uCaseFirst()}`;
    }
    return '';
  }

  public latLng(): LatLngExpression {
    const [lng, lat] = this.position.geometry.coordinates;

    return {
      lat,
      lng,
    };
  }
}
