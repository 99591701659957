import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-columns"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "row",
    "data-columns": _ctx.columns
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 8, _hoisted_1))
}