import { DriveStep } from 'driver.js';

const steps: DriveStep[] = [
  {
    element: 'toggle-property-save-state',
    popover: {
      title: 'Lagre tomt til senere',
      description:
        'Virker tomten interessant og relevant - lagre den til senere i prosjektet',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'generate-report',
    popover: {
      title: 'Rapport',
      description:
        'Last ned rapport for tomten som kan deles med andre involverte',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'documents-accordion',
    popover: {
      title: 'Dokumenter',
      description:
        'Last ned servitutter (grunnbok), gammel grunnbok og matrikkelrapport for eiendommen. Ingen skjulte overraskelser',
      side: 'right',
      align: 'start',
    },
  },
  {
    element: 'more-details',
    popover: {
      title: 'Regulering, plan m.m.',
      description:
        'Se gjeldende planer, seksjoner, bygningsinformasjon, kulturminner og grunnforurensning for eiendommen',
      side: 'right',
      align: 'start',
    },
  },
];

export default steps;
