
import { defineComponent } from 'vue';
import Seo from '@/components/SEO/SEO.vue';
import PfHeader from '@/components/Header/HeaderComponent.vue';

export default defineComponent({
  name: 'not-found',
  components: {
    Seo,
    PfHeader,
  },
});
