import {
  RealtyOwnerShip,
  RealtyRegisterType,
  RealtyLink,
  RealtyType,
} from '../RealtyTypes';
import CadastreIdent from './CadastreIdent';
import Person from './Person';

export default class Ownership implements RealtyOwnerShip {
  public ident: {
    cadastreIdent: CadastreIdent;
    ownershipNumber: number;
    cadastreLevel: string;
  };

  public key: string;

  public cadastreLevel: RealtyRegisterType;

  public fraction: {
    numerator: number;
    denominator: number;
  };

  public links: {
    self: RealtyLink;
    ownedCadastre: RealtyLink;
    owningPerson: RealtyLink;
    owningCadastre: RealtyLink;
  };

  public registered: boolean;

  public ownedCadastreIdent: CadastreIdent;

  public owningCadastreIdent: CadastreIdent | null;

  public useOfCadastre: RealtyType;

  public dwellingType: RealtyType;

  public ownerType: RealtyType;

  public owningPerson: Person | null;

  public numberOfOwnersOnLevel: number;

  public isResidence: boolean;

  public objectTypeName: string;

  constructor(item: RealtyOwnerShip) {
    this.ident = {
      cadastreIdent: new CadastreIdent(item.ident.cadastreIdent),
      ownershipNumber: item.ident.ownershipNumber,
      cadastreLevel: item.ident.cadastreLevel,
    };
    this.key = item.key;
    this.cadastreLevel = item.cadastreLevel;
    this.fraction = item.fraction;
    this.links = item.links;
    this.registered = item.registered;
    this.ownedCadastreIdent = new CadastreIdent(item.ownedCadastreIdent);
    this.owningCadastreIdent = null;
    this.useOfCadastre = item.useOfCadastre;
    this.dwellingType = item.dwellingType;
    this.ownerType = item.ownerType;

    if (item.owningPerson && item.owningPerson !== null) {
      this.owningPerson = new Person(item.owningPerson);
    } else {
      this.owningPerson = null;
    }

    if (item.owningCadastreIdent && item.owningCadastreIdent !== null) {
      this.owningCadastreIdent = new CadastreIdent(item.owningCadastreIdent);
    }

    this.numberOfOwnersOnLevel = item.numberOfOwnersOnLevel;
    this.isResidence = item.isResidence;
    this.objectTypeName = item.objectTypeName;
  }

  public ownerName(): string {
    if (this.ownerType.code === 'PERSON') {
      const person = this.getOwningPerson();

      if (person) {
        return person.fullName();
      }
    } else if (this.ownerType.code === 'CADASTRE') {
      const ident = this.getOwningCadastreIdent();

      if (ident) {
        return ident.matrikkelText();
      }
    }

    return '?';
  }

  public getOwnedCadastreIdent(): CadastreIdent {
    return this.ident.cadastreIdent;
  }

  public getOwningCadastreIdent(): CadastreIdent | null {
    return this.owningCadastreIdent;
  }

  public getOwningPerson(): Person | null {
    return this.owningPerson;
  }
}
