
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    condition: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const isConditional = computed(() => {
      if (typeof props.condition !== 'boolean') {
        console.warn(
          'Invalid conditional value, did you forget to call a function?'
        );
        return null;
      }
      return props.condition;
    });
    return { isConditional };
  },
});
