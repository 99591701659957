import { RealtyCadastreIdent } from '@/data/RealtyTypes';

export default class CadastreIdent implements RealtyCadastreIdent {
  public municipalityNumber: number;

  public cadastralUnitNumber: number;

  public unitNumber: number;

  public leaseholdUnitNumber: number;

  public sectionNumber: number;

  public section: boolean;

  public leasehold: boolean;

  constructor(ident: RealtyCadastreIdent) {
    this.municipalityNumber = ident.municipalityNumber;
    this.cadastralUnitNumber = ident.cadastralUnitNumber;
    this.unitNumber = ident.unitNumber;
    this.leaseholdUnitNumber = ident.leaseholdUnitNumber;
    this.sectionNumber = ident.sectionNumber;
    this.section = ident.section;
    this.leasehold = ident.leasehold;
  }

  public key(): string {
    return `${this.municipalityNumber}-${this.cadastralUnitNumber}-${this.unitNumber}-${this.leaseholdUnitNumber}-${this.sectionNumber}`;
  }

  public matrikkelText(): string {
    return `${this.municipalityNumber}/${this.cadastralUnitNumber}-${this.unitNumber}-${this.leaseholdUnitNumber}-${this.sectionNumber}`;
  }
}
