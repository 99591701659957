
import { defineComponent, ref } from 'vue';
import ProjectPicker from '@/components/Projects/ProjectPicker.vue';
import router from '@/router';
import IconPlus from '@icons/regular/plus.svg';
import UserCircleIcon from '@icons/regular/user-circle.svg';
import SignOutIcon from '@icons/regular/sign-out.svg';
import { coreStore, coreActions } from '@ambita/ambita-components-core';
import Popover from '@/components/helper/Popover.vue';
import { useProjectStore } from '@/store/project';
import CreateProject from '../Projects/CreateProject.vue';

export default defineComponent({
  name: 'Header',
  components: {
    IconPlus,
    UserCircleIcon,
    SignOutIcon,
    Popover,
    ProjectPicker,
    CreateProject,
  },
  setup() {
    const projectStore = useProjectStore();
    const isProjectModalOpen = ref(false);

    const showCreateProjectModal = () => {
      isProjectModalOpen.value = true;
    };

    const closeCreateProjectModal = () => {
      isProjectModalOpen.value = true;
    };

    const createProject = (id: string) => {
      closeCreateProjectModal();
      router.push(`/prosjekter/${id}/Prosjektoversikt`);
    };

    return {
      isProjectModalOpen,
      projectStore,
      createProject,
      closeCreateProjectModal,
      showCreateProjectModal,
    };
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isProjectsView: this.$route.path.indexOf('prosjekt') !== -1,
    };
  },
  methods: {
    getUser(): string {
      return coreStore.getUserName();
    },
    getUserLogonIdent(): string {
      return coreStore.getUser().logonIdent;
    },
    logOut(): void {
      coreActions.logout();

      router.push('/logg-inn');
    },
  },
});
