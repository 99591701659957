/* eslint-disable max-classes-per-file */
import { iot, mqtt5 } from 'aws-iot-device-sdk-v2/lib/browser';
import { coreStore } from '@ambita/ambita-components-core';
import logger from '@/utilities/Logger';

const REPORT_TOPIC = 'propfinder/report';

enum IoTConnectionState {
  Disconnected,
  Connecting,
  Connected,
}

export enum ReportStatus {
  Started = 'STARTED',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Finished = 'FINISHED',
}

export interface IoTData {
  data: string;
  status: ReportStatus;
}

export class IoTEvent extends Event {
  readonly data: IoTData;

  constructor(type: string, data: IoTData) {
    super(type);
    this.data = data;
  }
}

class IoTClient {
  private client: mqtt5.Mqtt5Client | undefined;

  private state = IoTConnectionState.Disconnected;

  private decoder = new TextDecoder();

  async connect(): Promise<boolean> {
    if (this.state !== IoTConnectionState.Disconnected) {
      return Promise.resolve(false);
    }

    logger.debug('IoT client connecting');

    const tokenOrPromise = coreStore.getAccessToken();

    const tokenPromise = new Promise<string>((resolve, reject) => {
      if (typeof tokenOrPromise === 'string') {
        resolve(tokenOrPromise);
      } else {
        tokenOrPromise.then(resolve).catch(reject);
      }
    });

    return tokenPromise
      .then((token) => this.connectMqtt(coreStore.getLogonIdent(), token))
      .then(() => {
        return Promise.resolve(true);
      });
  }

  disconnect() {
    logger.debug('IoT client disconnect');
    this.client?.stop();
    this.state = IoTConnectionState.Disconnected;
  }

  private async connectMqtt(userId: string, token: string) {
    if (!process.env.VUE_APP_CORE_ENV) {
      logger.error('Application core env not defined');
      return;
    }

    const AUTHORIZER_NAME = `report-iot-authorizer-${process.env.VUE_APP_CORE_ENV}`;

    const customAuthConfig: iot.MqttConnectCustomAuthConfig = {
      username: userId,
      authorizerName: encodeURIComponent(AUTHORIZER_NAME),
      tokenKeyName: encodeURIComponent('propfinder'),
      tokenValue: token,
      tokenSignature: '-',
    };

    const iotHost = process.env.VUE_APP_REPORT_IOT_HOST;
    if (!iotHost) {
      logger.error('IoT host name not defined');
      return;
    }
    logger.debug(`Connect MQTT to endpoint ${iotHost}`);

    const builder =
      iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithCustomAuth(
        iotHost,
        customAuthConfig
      );

    try {
      this.state = IoTConnectionState.Connecting;

      const client: mqtt5.Mqtt5Client = new mqtt5.Mqtt5Client(builder.build());

      client.on('error', (error) => {
        logger.error(`MQTT error event: ${error.toString()}`);
      });

      client.on('messageReceived', (event) =>
        this.handleMessageReceived(event)
      );

      client.on('connectionFailure', (event: mqtt5.ConnectionFailureEvent) => {
        logger.error(`MQTT client connection failure: ${event.error}`);
        this.state = IoTConnectionState.Disconnected;
      });

      client.on('connectionSuccess', (event: mqtt5.ConnectionSuccessEvent) => {
        logger.info('MQTT client connected');

        const topicFilter = `${REPORT_TOPIC}/${userId.toLowerCase()}`;
        client.subscribe({
          subscriptions: [
            {
              qos: mqtt5.QoS.AtLeastOnce,
              topicFilter,
            },
          ],
        });
        logger.info(`subscribed to topic ${topicFilter}`);
        this.state = IoTConnectionState.Connected;
      });

      client.on('disconnection', (event: mqtt5.DisconnectionEvent) => {
        logger.info('mqttClient disconnected');
        this.state = IoTConnectionState.Disconnected;
      });

      this.client = client;
      this.client.start();
    } catch (error) {
      logger.error('IoT error', error);
    }
  }

  private handleMessageReceived(eventData: mqtt5.MessageReceivedEvent) {
    logger.info(`IoT message received`);

    if (eventData.message.payload) {
      const json = this.decoder.decode(
        eventData.message.payload as ArrayBuffer
      );
      const iotData = JSON.parse(json) as IoTData;
      logger.debug(`With payload: ${JSON.stringify(iotData)}`);

      document.dispatchEvent(new IoTEvent('iotMessageReceived', iotData));
    }
  }
}

export const iotClient: IoTClient = new IoTClient();
