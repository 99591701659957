
import { defineComponent, PropType, ref, watch } from 'vue';
import Property from '@/data/wrapper/Property';
import SoilContamination from '@/data/wrapper/SoilContamination';
import PropertySelection from '@/data/wrapper/PropertySelection';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import ArrowSquareOutIcon from '@icons/regular/arrow-square-out.svg';
import PanelBackButton from '@/components/PanelSystem/PanelBackButton.vue';
import Realty from '@/data/Realty';
import { useRoute } from 'vue-router';
import PanelGroup from '@/components/PanelSystem/PanelGroup.vue';

export default defineComponent({
  name: 'SoilContaminationPanel',
  components: {
    PanelRow,
    PanelColumn,
    PanelBackButton,
    ArrowSquareOutIcon,
    PanelGroup,
  },
  props: {
    cadastreKey: {
      type: String,
      required: true,
    },
    soilContaminationKey: {
      type: String,
      required: true,
    },
    property: {
      type: Object as PropType<Property> | null,
      default: null,
    },
    selectedProperties: {
      type: Object as PropType<PropertySelection>,
      default: null,
    },
  },
  setup(props) {
    const route = useRoute();
    const soilContamination = ref<SoilContamination | null>(null);
    const isLoading = ref(false);
    const errorMessage = ref<string | null>(null);

    const getSoilContamination = () => {
      if (route.params.soilContaminationKey) {
        isLoading.value = true;
        errorMessage.value = null;
        Realty.getSoilContamination(props.soilContaminationKey)
          .then((result) => {
            soilContamination.value = result;
            isLoading.value = false;
          })
          .catch(() => {
            errorMessage.value = `Klarte ikke hente informasjon om bygning ${props.soilContaminationKey}`;
          });
      }
    };

    watch(() => props.soilContaminationKey, getSoilContamination, {
      immediate: true,
    });

    return { soilContamination, isLoading, errorMessage };
  },

  emits: ['retrievePropertyByKeyAndShow', 'removeMarker'],
});
