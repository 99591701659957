import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"


import { CreateProject } from '@/data/Projects';
import { defineComponent, ref } from 'vue';
import Logger from '@/utilities/Logger';
import {
  AuxInput,
  AuxSpinner,
  AuxModal,
  AuxValidationWrapper,
} from '@ambita/design-system';

const __default__ = defineComponent({
  name: 'CreateProject',
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  emits: ["create", "close-modal"],
  setup(__props, { emit: __emit }) {

const name = ref('');
const error = ref<string[]>([]);
const loading = ref<boolean>(false);
const emits = __emit;

const onInput = (input: string): void => {
  name.value = input;
};

const onSubmit = (): void => {
  loading.value = true;
  error.value = [];
  CreateProject(name.value)
    .then((result) => {
      Logger.info(`Project created: ${result}`);
      emits('create', result);
    })
    .catch((e: Error) => {
      if (e && e.name === 'InvalidParameterError') {
        error.value.push('Ugyldig prosjektnavn');
      } else {
        Logger.error(e);
        error.value.push('Klarte ikke opprette prosjekt.');
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AuxModal), {
    title: "Nytt prosjekt",
    size: "small",
    onCreateProject: onSubmit,
    onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (emits('close-modal'))),
    actions: [
      {
        label: 'Opprett prosjekt',
        emit: 'create-project',
        primary: true,
      },
      {
        label: 'Avbryt',
        emit: 'close-modal',
      },
    ]
  }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(_unref(AuxSpinner), {
            key: 0,
            size: "medium"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Laster")
            ])),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(AuxValidationWrapper), { errorMessages: error.value }, {
        default: _withCtx(() => [
          (!loading.value)
            ? (_openBlock(), _createBlock(_unref(AuxInput), {
                key: 0,
                label: "Prosjektnavn",
                onInput: onInput,
                value: name.value
              }, null, 8, ["value"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["errorMessages"])
    ]),
    _: 1
  }))
}
}

})