
import { defineComponent } from 'vue';
import CaretLeftIcon from '@icons/regular/caret-left.svg';

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  components: {
    CaretLeftIcon,
  },
});
