import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import Summary from '@/components/PropertyDetailsPanel/SummaryComponent/Summary.vue';
import PropertyDetails from '@/components/PropertyDetailsPanel/PropertyDetails/PropertyDetails.vue';
import DemographyExtentPanel from '@/components/PropertyDetailsPanel/Demography/DemographyExtentPanel.vue';
import Heritage from '@/components/PropertyDetailsPanel/PropertyDetails/Panels/HeritagePanel.vue';
import SoilContamination from '@/components/PropertyDetailsPanel/PropertyDetails/Panels/SoilContaminationPanel.vue';
import Building from '@/components/PropertyDetailsPanel/PropertyDetails/Panels/BuildingPanel.vue';
import { coreStore } from '@ambita/ambita-components-core';
import PTM from '@/utilities/PTM';
import NotFoundPage from '@/views/NotFoundPage/NotFoundPage.vue';
import { iotClient } from '@/utilities/iotClient';

const getUserType = (): string => {
  if (coreStore.isAuthenticatedNotAnonymousCustomer() !== true) {
    return 'unauthenticated';
  }

  const state = coreStore.getState();

  if (
    state !== null &&
    state.user &&
    state.user.organization.orgnr === '945811714'
  ) {
    return 'internal';
  }

  return 'external';
};

coreStore.on('LOGIN_SUCCESS_EVENT', (): void => {
  PTM.event_old('LogIn', { event_key: getUserType() });
  iotClient.connect();
});

coreStore.on('LOGOUT_EVENT', (): void => {
  iotClient.disconnect();
});

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Frontpage',
    redirect: '/prosjekter',
  },
  {
    path: '/informasjonskapsler',
    name: 'Informasjonskapsler',
    component: () =>
      import(
        /* webpackChunkName: "Redirect" */ '../views/Redirect/Redirect.vue'
      ),
    props: {
      location: `${process.env.VUE_APP_LANDING_PAGE}/informasjonskapsler`,
    },
  },
  {
    path: '/betalingsvilkår',
    name: 'Betalingsvilkår',
    component: () =>
      import(
        /* webpackChunkName: "Redirect" */ '../views/Redirect/Redirect.vue'
      ),
    props: {
      location: `${process.env.VUE_APP_LANDING_PAGE}/betalingsvilk%C3%A5r`,
    },
    alias: ['/betalingsvilk%C3%A5r', '/betalingsvilkar', '/betalingsvilkaar'],
  },
  {
    path: '/beta-tilgang',
    name: 'Betatilgang',
    component: () =>
      import(
        /* webpackChunkName: "Redirect" */ '../views/Redirect/Redirect.vue'
      ),
    props: {
      location: `${process.env.VUE_APP_LANDING_PAGE}/bli-kunde`,
    },
    alias: [
      '/bli-kunde',
      '/bli-kunde/innmelding',
      '/beta-tilgang/innmelding',
      '/bli-kunde/takk-for-henvendelsen',
      '/beta-tilgang/takk-for-henvendelsen',
    ],
  },
  {
    path: '/prosjekter/:projectId/kart',
    name: 'PropFinder',
    component: () =>
      import(
        /* webpackChunkName: "PropertyFinder" */ '../views/PropertyFinder/PropertyFinder.vue'
      ),
    meta: {
      authenticated: true,
    },
    children: [
      {
        path: 'valgte',
        component: Summary,
        props: true,
      },
      {
        path: 'demografi',
        component: DemographyExtentPanel,
        props: false,
      },
      {
        path: ':cadastreKey',
        component: PropertyDetails,
        props: true,
      },
      {
        path: ':cadastreKey/kulturminner/:heritageKey',
        component: Heritage,
        props: true,
      },
      {
        path: ':cadastreKey/grunnforurensning/:soilContaminationKey',
        component: SoilContamination,
        props: true,
      },
      {
        path: ':cadastreKey/bygninger/:buildingKey',
        component: Building,
        props: true,
      },
      {
        path: ':cadastreKey/notater',
        component: () =>
          import(
            /* webpackChunkName: "Notes" */ '../components/PropertyDetailsPanel/Notes/Notes.vue'
          ),
        props: true,
      },
    ],
  },
  {
    path: '/prosjekter',
    name: 'Prosjekter',
    meta: {
      authenticated: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ProjectIndex" */ '../views/Projects/index.vue'
      ),
    children: [
      {
        name: 'prosjekter-noname',
        path: '',
        component: () =>
          import(
            /* webpackChunkName: "Projects" */ '../views/Projects/Projects.vue'
          ),
      },
      {
        path: ':projectId/:activeTab?',
        component: () =>
          import(
            /* webpackChunkName: "Project" */ '../views/Projects/Project.vue'
          ),
        props: true,
      },
    ],
  },
  {
    path: '/logg-inn',
    name: 'Logg inn',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login/Login.vue'),
    alias: [
      '/login',
      '/logginn',
      '/Logg-Inn',
      '/Logg-inn',
      '/LoggInn',
      '/LogIn',
    ],
  },
  {
    path: '/logg-inn',
    name: 'Logg inn',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/Login/Login.vue'),
    alias: [
      '/login',
      '/logginn',
      '/Logg-Inn',
      '/Logg-inn',
      '/LoggInn',
      '/LogIn',
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.matched.length === 0) {
      return next({ path: '/404' });
    }
    if (to.matched.some((record) => record.meta.authenticated)) {
      if (coreStore.isAuthenticatedNotAnonymousCustomer() === true) {
        return next();
      }

      return next({ path: '/logg-inn' });
    }

    return next();
  }
);

router.afterEach((to) => {
  PTM.routeChange(to.fullPath, getUserType());
  window.scrollTo(0, 0);
});

export default router;
