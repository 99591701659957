
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    illustrationSize: {
      type: String,
      required: false,
      default: 'large',
    },
  },
});
