import { Ref } from 'vue';

export const DataTestId = (testId: Ref<string | undefined>) => {
  const getTestId = (id: string): string | undefined => {
    if (testId.value) {
      return `${testId.value}-${id}`;
    }

    return undefined;
  };

  return {
    getTestId,
  };
};

export default { DataTestId };
