// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DemographyPanel-module_panel_F5D7V .number-text{display:inline-block;padding:2% 4%;width:33.3333%;vertical-align:top}.DemographyPanel-module_panel_F5D7V .AreaSelector{margin:4%}.DemographyPanel-module_panel_F5D7V .tab{margin:4%}.DemographyPanel-module_panel_F5D7V .tab>div:not(:first-child){border-top:.125rem solid #d2d2d2;padding-top:4%}.DemographyPanel-module_panel_F5D7V .VerticalBarChart{border-bottom:.0625rem solid #d2d2d2}.DemographyPanel-module_panel_F5D7V .ToggleSwitchWrap{display:flex;justify-content:flex-end}.DemographyPanel-module_panel_F5D7V .ToggleSwitchWrap .ToggleSwitchPad{flex:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "DemographyPanel-module_panel_F5D7V"
};
export default ___CSS_LOADER_EXPORT___;
