import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["index"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_arrow_square_out_icon = _resolveComponent("arrow-square-out-icon")!
  const _component_list = _resolveComponent("list")!
  const _component_list_collapsible_block = _resolveComponent("list-collapsible-block")!

  return (_openBlock(), _createBlock(_component_list_collapsible_block, {
    label: _ctx.type,
    count: _ctx.plans.length,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createVNode(_component_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan) => {
            return (_openBlock(), _createElementBlock("li", {
              key: plan,
              index: plan.planID,
              class: _normalizeClass(_ctx.panelStyle.details__li__complex)
            }, [
              _createElementVNode("strong", null, _toDisplayString(plan.planName ? plan.planName : 'Ukjent plan'), 1),
              (plan.planID)
                ? (_openBlock(), _createBlock(_component_panel_row, {
                    key: 0,
                    columns: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          (plan.planID)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_2, _cache[0] || (_cache[0] = [
                                _createElementVNode("strong", null, "Plan ID:", -1)
                              ])))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          (plan.planID)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(plan.planID), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (plan.planType)
                ? (_openBlock(), _createBlock(_component_panel_row, {
                    key: 1,
                    columns: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createElementVNode("p", null, [
                            _createElementVNode("strong", null, "Type:")
                          ], -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(plan.planType), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (plan.planDecision)
                ? (_openBlock(), _createBlock(_component_panel_row, {
                    key: 2,
                    columns: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createElementVNode("p", null, [
                            _createElementVNode("strong", null, "Bestmls.:")
                          ], -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(plan.planDecision), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (plan.planStatus)
                ? (_openBlock(), _createBlock(_component_panel_row, {
                    key: 3,
                    columns: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createElementVNode("p", null, [
                            _createElementVNode("strong", null, "Status:")
                          ], -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(plan.planStatus), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (plan.lawReference)
                ? (_openBlock(), _createBlock(_component_panel_row, {
                    key: 4,
                    columns: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("p", null, [
                            _createElementVNode("strong", null, "Lovref.:")
                          ], -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(plan.lawReference), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (plan.updatedDate)
                ? (_openBlock(), _createBlock(_component_panel_row, {
                    key: 5,
                    columns: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("p", null, [
                            _createElementVNode("strong", null, "Oppdatert:")
                          ], -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(plan.updatedDate), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (plan.link)
                ? (_openBlock(), _createBlock(_component_panel_row, { key: 6 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_panel_column, { title: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            href: _ctx.sanitizeLink(plan.link),
                            target: "_blank",
                            rel: "noopener noreferrer"
                          }, [
                            _createVNode(_component_arrow_square_out_icon, {
                              width: "18",
                              height: "18"
                            }),
                            _cache[6] || (_cache[6] = _createTextVNode("Ekstern lenke til planen "))
                          ], 8, _hoisted_4)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 10, _hoisted_1))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["label", "count", "loading"]))
}