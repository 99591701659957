
import { defineComponent, PropType } from 'vue';
import Heritage from '@/data/wrapper/Heritage';
import Cadastre from '@/data/wrapper/Cadastre';
import { useProjectStore } from '@/store/project';
import ListCollapsibleBlock from './Blocks/ListCollapsibleBlock.vue';
import List from './Blocks/List.vue';
import ListItem from './Blocks/ListItem.vue';

export default defineComponent({
  name: 'HeritagesList',
  components: {
    ListCollapsibleBlock,
    List,
    ListItem,
  },
  props: {
    heritages: {
      type: Object as PropType<Heritage[]>,
      required: true,
    },
    cadastre: {
      type: Object as PropType<Cadastre>,
      required: true,
    },
  },
  setup() {
    const projectStore = useProjectStore();
    return {
      projectStore,
    };
  },
});
