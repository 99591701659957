import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["index", "onMouseenter", "onFocus"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_aux_modal = _resolveComponent("aux-modal")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_caret_right_icon = _resolveComponent("caret-right-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.state.showDeleteModal)
      ? (_openBlock(), _createBlock(_component_aux_modal, {
          key: 0,
          title: "Fjern lagrede tomter",
          size: "medium",
          actions: _ctx.modalActions,
          onCloseModal: _ctx.toggleDeletePropertiesDialog,
          onDelete: _ctx.removeAllProperties
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Er du sikker på at du vil tømme listen over lagrede tomter? Handlingen kan ikke angres. ")
          ])),
          _: 1
        }, 8, ["actions", "onCloseModal", "onDelete"]))
      : _createCommentVNode("", true),
    _createVNode(_component_panel_row, { columns: "2" }, {
      default: _withCtx(() => [
        _createVNode(_component_panel_column, {
          title: "",
          style: {"width":"60%"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.panelStyle.title)
            }, " Lagrede tomter (" + _toDisplayString(_ctx.projectStore.selectedCadastres.length) + ") ", 3)
          ]),
          _: 1
        }),
        (_ctx.projectStore.selectedCadastres.length > 0)
          ? (_openBlock(), _createBlock(_component_panel_column, {
              key: 0,
              title: "",
              style: {"width":"40%","border":"none"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  href: "javascript:void(0)",
                  class: _normalizeClass(_ctx.panelStyle.title),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDeletePropertiesDialog && _ctx.toggleDeletePropertiesDialog(...args)))
                }, " Fjern alle ", 2)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("ul", {
      class: _normalizeClass(_ctx.panelStyle.details__list)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectStore.selectedProperties, (property) => {
        return (_openBlock(), _createElementBlock("li", {
          key: property.cadastre.key,
          index: property,
          class: _normalizeClass(_ctx.panelStyle.details__list__item),
          onMouseenter: ($event: any) => (_ctx.handleMouseHover(property.cadastre.key)),
          onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOut && _ctx.handleMouseOut(...args))),
          onFocus: ($event: any) => (_ctx.handleMouseHover(property.cadastre.key)),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleMouseOut && _ctx.handleMouseOut(...args)))
        }, [
          _createElementVNode("button", {
            class: "remove-property",
            onClick: ($event: any) => (_ctx.removeProperty(property.cadastre.key))
          }, [
            _createVNode(_component_x_icon, {
              width: "30",
              height: "30"
            })
          ], 8, _hoisted_2),
          _createVNode(_component_router_link, {
            class: _normalizeClass(_ctx.panelStyle.details__list__item__link),
            to: `${_ctx.projectStore.getProjectRoute}/${property.cadastre.key}`,
            onClick: ($event: any) => (_ctx.navigateToProperty(property.cadastre.key))
          }, {
            default: _withCtx(() => [
              (property.address?.street?.streetName)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(property.address?.street?.streetName) + " " + _toDisplayString(property.address?.ident.addressNumber) + " " + _toDisplayString(property.address?.ident.letter), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(property.cadastre.key), 1)),
              _createVNode(_component_caret_right_icon, {
                width: "30",
                height: "30",
                class: _normalizeClass(_ctx.panelStyle.details__list__item__arrow)
              }, null, 8, ["class"])
            ]),
            _: 2
          }, 1032, ["class", "to", "onClick"])
        ], 42, _hoisted_1))
      }), 128))
    ], 2)
  ]))
}