import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadLink = _resolveComponent("DownloadLink")!

  return (_openBlock(), _createElementBlock("ul", null, [
    _createElementVNode("li", null, [
      _createVNode(_component_DownloadLink, {
        label: "Matrikkelrapport",
        link: _ctx.getReportUrl('simpletechnicalcadastrepdf'),
        "file-name": _ctx.getReportFileName('Simple_Technical_Land_Registry')
      }, null, 8, ["link", "file-name"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_DownloadLink, {
        label: "Grunnbokskopi",
        link: _ctx.getReportUrl('cadastrewithprivilegespdf'),
        "file-name": _ctx.getReportFileName('Cadastre_With_Privileges')
      }, null, 8, ["link", "file-name"])
    ]),
    (_ctx.cadastre.hasOldCadastre)
      ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
          _createVNode(_component_DownloadLink, {
            label: "Gammel grunnbok",
            link: _ctx.getOldCadastreReportUrl(),
            "file-name": _ctx.getReportFileName('Old_Cadastre')
          }, null, 8, ["link", "file-name"])
        ]))
      : _createCommentVNode("", true)
  ]))
}