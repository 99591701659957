import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBackButton = _resolveComponent("PanelBackButton")!
  const _component_panel_column = _resolveComponent("panel-column")!
  const _component_panel_row = _resolveComponent("panel-row")!
  const _component_arrow_square_out_icon = _resolveComponent("arrow-square-out-icon")!
  const _component_PanelGroup = _resolveComponent("PanelGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PanelBackButton, {
      to: 
      _ctx.$route.path.replace(
        `/grunnforurensning/${_ctx.$route.params.soilContaminationKey}`,
        ''
      )
    ,
      label: _ctx.$route.params.cadastreKey as string
    }, null, 8, ["to", "label"]),
    (_ctx.soilContamination)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.panelStyle.panel)
        }, [
          _createVNode(_component_PanelGroup, { title: "Grunnforurensning" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Lokalitetnavn" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.soilContamination.localityName.toLowerCase()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Påvirkningsgrad" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.soilContamination.degreeOfImpactType.text.toLowerCase()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_panel_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Ekstern kilde" }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: _ctx.soilContamination.links.soilContaminationWebpage.href,
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }, [
                        _cache[0] || (_cache[0] = _createTextVNode(" Les mer hos Miljødirektoratet ")),
                        _createVNode(_component_arrow_square_out_icon, {
                          width: "18",
                          height: "18"
                        })
                      ], 8, _hoisted_1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_PanelGroup, { title: "Hendelsesdatoer" }, {
            default: _withCtx(() => [
              _createVNode(_component_panel_row, { columns: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_panel_column, { title: "Sist oppdatert" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.soilContamination.lastUpdatedDate.ddmmyyyy), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_panel_column, { title: "Registreringsdato" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.soilContamination.registeredDate.ddmmyyyy), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}