import intro from './intro.tour';
import propertyPanel from './propertyPanel.tour';
import tools3d from './tools3d.tour';
import defaultTour from './default.tour';
import reportGenerator from './reportGenerator.tour';

export default {
  intro,
  propertyPanel,
  tools3d,
  defaultTour,
  reportGenerator,
};
