import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "empty-state-wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "subtitle"
}
const _hoisted_3 = {
  key: 2,
  class: "cta"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.illustration)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({
        [`illustration-size--${_ctx.illustrationSize}`]: true,
      })
        }, [
          _renderSlot(_ctx.$slots, "illustration", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.subtitle), 1))
      : _createCommentVNode("", true),
    (_ctx.$slots.cta)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "cta", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}