import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ProjectSaveFirstPropertyEmptyStateSvg from '@/assets/img/ProjectSaveFirstPropertyEmptyState.svg';
import EmptyState from '@/components/EmptyState/EmptyState.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'SummaryPropertiesEmptyState',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EmptyState, {
    title: "Ingen tomt valgt",
    subtitle: "Du må velge en tomt i kartet for å kunne se eiendomsinformasjon.\nDu kan også lagre flere tomter til prosjektet og se de i sammenheng under oppsummeringen."
  }, {
    illustration: _withCtx(() => [
      _createVNode(_unref(ProjectSaveFirstPropertyEmptyStateSvg))
    ]),
    cta: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})