
import { defineComponent } from 'vue';
import PanelTitle from './PanelTitle.vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: { PanelTitle },
});
