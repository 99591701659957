import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AngleIcon = _resolveComponent("AngleIcon")!

  return (_openBlock(), _createElementBlock("details", {
    class: _normalizeClass({
      accordion: true,
      [`accordion--${_ctx.size}`]: true,
    })
  }, [
    _createElementVNode("summary", null, [
      _renderSlot(_ctx.$slots, "summary", {}, undefined, true),
      _createVNode(_component_AngleIcon, { class: "caret" })
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}