
import { defineComponent, PropType } from 'vue';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import Ownership from '@/data/wrapper/Ownership';
import { useProjectStore } from '@/store/project';
import List from './Blocks/List.vue';
import ListCollapsibleBlock from './Blocks/ListCollapsibleBlock.vue';
import ListItem from './Blocks/ListItem.vue';

export default defineComponent({
  name: 'Owners',
  components: {
    PanelRow,
    PanelColumn,
    List,
    ListCollapsibleBlock,
    ListItem,
  },
  props: {
    ownerships: {
      type: Object as PropType<Ownership[]>,
      required: true,
    },
  },
  setup() {
    const projectStore = useProjectStore();

    return {
      projectStore,
    };
  },
  computed: {
    expandable(): boolean {
      return this.ownerships.length > 5;
    },
  },
});
