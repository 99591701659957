import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Onboarding from '@/components/Onboarding/Onboarding.vue';
import { useOnboardingStore } from '@/store/onboarding';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const onboardingStore = useOnboardingStore();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Onboarding, {
      steps: _unref(onboardingStore).steps
    }, null, 8, ["steps"]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})