import Point from '@arcgis/core/geometry/Point';
import Multipoint from '@arcgis/core/geometry/Multipoint';
import Polyline from '@arcgis/core/geometry/Polyline';
import Polygon from '@arcgis/core/geometry/Polygon';
import Extent from '@arcgis/core/geometry/Extent';
import StatisticDefinition from '@arcgis/core/rest/support/StatisticDefinition';
import Logger from '@/utilities/Logger';
import { getGeodataToken } from '@/data/xhr/geodataToken';

export type DemographyArea = 'basicDistrict' | 'municipality' | 'county';

export interface DemographyQuery {
  where: string;
  text: string;
  objectIds: number[] | '';
  time: string;
  timeRelation:
    | 'esriTimeRelationOverlaps'
    | 'esriTimeRelationOverlapsStartWithinEnd'
    | 'esriTimeRelationAfterStartOverlapsEnd'
    | 'esriTimeRelationWithin';
  geometry: string | Extent | Point | Polyline | Multipoint | Polygon;
  geometryType:
    | 'esriGeometryEnvelope'
    | 'esriGeometryPoint'
    | 'esriGeometryPolyline'
    | 'esriGeometryMultipoint'
    | 'esriGeometryPolygon';
  inSR: string;
  spatialRel:
    | 'esriSpatialRelIntersects'
    | 'esriSpatialRelContains'
    | 'esriSpatialRelCrosses'
    | 'esriSpatialRelEnvelopeIntersects'
    | 'esriSpatialRelIndexIntersects'
    | 'esriSpatialRelOverlaps'
    | 'esriSpatialRelTouches'
    | 'esriSpatialRelWithin'
    | 'esriSpatialRelRelation';
  distance: string;
  units:
    | 'esriSRUnit_Foot'
    | 'esriSRUnit_Meter'
    | 'esriSRUnit_Kilometer'
    | 'esriSRUnit_StatuteMile'
    | 'esriSRUnit_NauticalMile'
    | 'esriSRUnit_USNauticalMile';
  relationParam: '';
  outFields: string;
  returnGeometry: boolean;
  returnTrueCurves: boolean;
  maxAllowableOffset: string;
  geometryPrecision: string;
  outSR: string;
  havingClause: string;
  returnIdsOnly: boolean;
  returnCountOnly: boolean;
  orderByFields: string;
  groupByFieldsForStatistics: string;
  outStatistics: StatisticDefinition[] | '';
  returnZ: boolean;
  returnM: boolean;
  gdbVersion: string;
  historicMoment: string;
  returnDistinctValues: boolean;
  resultOffset: string;
  resultRecordCount: string;
  returnExtentOnly: boolean;
  sqlFormat: 'none' | 'standard' | 'native';
  datumTransformation: string;
  parameterValues: string;
  rangeValues: string;
  quantizationParameters: string;
  featureEncoding: 'esriDefault' | 'esriCompressedShapeBuffer';
  f: 'html' | 'pjson' | 'kmz' | 'geojson' | 'pbf';
}

export interface DemographyResult {
  displayFieldName: string;
  features: {
    attributes: Record<string, number | string>;
  }[];
  fieldAliases: Record<string, string>;
  fields: {
    name: string;
    type: string;
    alias: string;
  }[];
}

export interface DemographyFiltered {
  numbers: Record<string, string>;
  text: Record<string, string>;
  percent: Record<string, number>;
}

export interface DemographyDataField {
  types: {
    basicDistrict: number;
    municipality: number;
    county: number;
  };
  numbers: string[];
  text: string[];
  textAvg?: string[];
  total: string;
  labels: Record<string, string>;
  units?: Record<string, string>;
}

export interface DemographyAreaNames {
  basicDistrict: string;
  municipality: string;
  county: string;
}

const NumberFormat = new Intl.NumberFormat('nb-NO');

export const DemographyDataFields: Record<string, DemographyDataField> = {
  age: {
    types: {
      basicDistrict: 57,
      municipality: 38,
      county: 19,
    },
    numbers: [
      'aldu_5',
      'ald6_12',
      'ald13_15',
      'ald16_18',
      'ald19_23',
      'ald24_34',
      'ald35_44',
      'ald45_54',
      'ald55_64',
      'ald65_74',
      'ald75_84',
      'ald85_o',
    ],
    text: ['tot_anta', 'tot_kvinn', 'tot_menn'],
    textAvg: ['snitt_alde', 'snitt_kvinn', 'snitt_menn'],
    total: 'tot_anta',
    labels: {
      aldu_5: '5 år og under',
      ald6_12: '6-12 år',
      ald13_15: '13-15 år',
      ald16_18: '16-18 år',
      ald19_23: '19-23 år',
      ald24_34: '24-34 år',
      ald35_44: '35-44 år',
      ald45_54: '45-54 år',
      ald55_64: '55-64 år',
      ald65_74: '65-74 år',
      ald75_84: '75-84 år',
      ald85_o: '85 år og over',
      tot_anta: 'Totalt antall personer',
      tot_kvinn: 'Totalt antall kvinner',
      tot_menn: 'Totalt antall menn',
      snitt_alde: 'Snittalder',
      snitt_kvinn: 'Snittalder kvinner',
      snitt_menn: 'Snittalder menn',
    },
    units: {
      snitt_alde: ' år',
      snitt_kvinn: ' år',
      snitt_menn: ' år',
    },
  },
  education: {
    types: {
      basicDistrict: 73,
      municipality: 55,
      county: 36,
    },
    numbers: [
      'grunnskole',
      'videregaen',
      'hogskole_u',
      'hogskole_1',
      'ingen_uopp',
    ],
    text: ['tot_u_niva'],
    total: 'tot_u_niva',
    labels: {
      grunnskole: 'Grunnskole',
      videregaen: 'Videregående',
      hogskole_u: 'Høgskole/universitet lavere utdanning',
      hogskole_1: 'Høgskole/universitet høyere utdanning',
      ingen_uopp: 'Ingen eller ikke oppgitt',
      tot_u_niva: 'Antall personer med utdanningsnivå',
    },
  },
  educationalField: {
    types: {
      basicDistrict: 72,
      municipality: 54,
      county: 35,
    },
    numbers: [
      'allmenne_f',
      'human_este',
      'larerutdan',
      'samfunnsfa',
      'okonomiske',
      'natvit_han',
      'helse_sosi',
      'primarnari',
      'samferds_s',
      'uoppgitt',
    ],
    text: ['tot_u_felt'],
    total: 'tot_u_felt',
    labels: {
      allmenne_f: 'Allmenne fag',
      human_este: 'Humanistiske og estetiske fag',
      larerutdan: 'Lærerutdanning',
      samfunnsfa: 'Samfunnsfag',
      okonomiske: 'Økonomiske fag',
      natvit_han: 'Naturvitenskap',
      helse_sosi: 'Helse- og sosialfag',
      primarnari: 'Primærnæringer',
      samferds_s: 'Samferdsel',
      uoppgitt: 'Uoppgitt',
      tot_u_felt: 'Antall personer med utdanningsfelt',
    },
  },
  income: {
    types: {
      basicDistrict: 69,
      municipality: 51,
      county: 32,
    },
    numbers: [
      'int0',
      'int0_100',
      'int100_200',
      'int200_300',
      'int300_400',
      'int400_500',
      'int500_600',
      'int600_700',
      'int700_800',
      'int800_1000',
      'int1000_1500',
      'int1500_',
    ],
    text: ['ant_personer'],
    textAvg: ['snitt_innt'],
    total: 'ant_personer',
    labels: {
      int0: '= 0',
      int0_100: '1 - 99.999',
      int100_200: '100.000 - 199.999',
      int200_300: '200.000 - 299.999',
      int300_400: '299.000 - 399.999',
      int400_500: '400.000 - 499.999',
      int500_600: '500.000 - 599.999',
      int600_700: '600.000 - 699.999',
      int700_800: '700.000 - 799.999',
      int800_1000: '800.000 - 999.999',
      int1000_1500: '1.000.000 - 1.499.999',
      int1500_: '> 1.500.000',
      ant_personer: 'Antall personer med formueinformasjon',
      snitt_innt: 'Gjennomsnittlig inntekt',
    },
    units: {
      snitt_innt: ' kr',
    },
  },
  fortune: {
    types: {
      basicDistrict: 65,
      municipality: 47,
      county: 28,
    },
    numbers: [
      'for0',
      'for0_50',
      'for50_200',
      'for200_400',
      'for400_700',
      'for700_1000',
      'for1000_2000',
      'for2000_3000',
      'for3000_4000',
      'for4000_',
    ],
    text: ['ant_personer'],
    textAvg: ['snitt_form'],
    total: 'ant_personer',
    labels: {
      for0: '= 0',
      for0_50: '1 - 49.999',
      for50_200: '50.000 - 199.999',
      for200_400: '200.000 - 399.999',
      for400_700: '400.000 - 699.999',
      for700_1000: '700.000 - 999.999',
      for1000_2000: '1.000.000 - 1.999.999',
      for2000_3000: '2.000.000 - 2.999.999',
      for3000_4000: '3.000.000 - 3.999.999',
      for4000_: '> 4.000.000',
      ant_personer: 'Antall personer med formueinformasjon',
      snitt_form: 'Gjennomsnittlig formue',
    },
    units: {
      snitt_form: ' kr',
    },
  },
  maritalStatus: {
    types: {
      basicDistrict: 71,
      municipality: 53,
      county: 34,
    },
    numbers: ['ugift_kvin', 'ugift_mann', 'gift', 'separert_s', 'enke_enkem'],
    text: ['tot_sivil'],
    total: 'tot_sivil',
    labels: {
      ugift_kvin: 'Ugifte kvinner',
      ugift_mann: 'Ugifte menn',
      gift: 'Gifte',
      separert_s: 'Separerte',
      enke_enkem: 'Enker og enkemenn',
      tot_sivil: 'Antall personer med sivilstand',
    },
  },
  houseHolds: {
    types: {
      basicDistrict: 66,
      municipality: 48,
      county: 29,
    },
    numbers: [
      'flerfamili',
      'enslig_u_b',
      'enslig_m_b',
      'par_u_barn',
      'par_m_barn',
    ],
    text: ['total_hus', 'par', 'enslig'],
    total: 'total_hus',
    labels: {
      flerfamili: 'Flerfamilier',
      enslig_u_b: 'Enslige uten barn',
      enslig_m_b: 'Enslige med barn',
      par_u_barn: 'Par uten barn',
      par_m_barn: 'Par med barn',
      total_hus: 'Antall personer totalt',
      par: 'Totalt antall par',
      enslig: 'Totalt antall enslige',
    },
  },
  buildingsType: {
    types: {
      basicDistrict: 70,
      municipality: 52,
      county: 33,
    },
    numbers: [
      'blokk',
      'bofelleskap',
      'enebolig',
      'rekkehus',
      'tomannsbolig',
      'annen_bo',
    ],
    text: ['tot_bolig'],
    total: 'tot_bolig',
    labels: {
      blokk: 'Blokk',
      bofelleskap: 'Bofellesskap',
      enebolig: 'Enebolig',
      rekkehus: 'Rekkehus',
      tomannsbolig: 'Tomannsbolig',
      annen_bo: 'Annen bolig',
      tot_bolig: 'Antall personer med boligtype',
    },
  },
  employees: {
    types: {
      basicDistrict: 58,
      municipality: 39,
      county: 20,
    },
    numbers: ['ant_bo', 'ant_arb', 'ant_ut', 'ant_inn', 'bef_dag'],
    text: ['ant_bo', 'ant_arb', 'ant_ut', 'ant_inn', 'bef_dag', 'tot_anta'],
    total: 'tot_anta',
    labels: {
      ant_bo: 'Antall bosatte',
      ant_arb: 'Antall arbeidsplasser',
      ant_ut: `Antall utpendlere`,
      ant_inn: `Antall innpendlere`,
      bef_dag: 'Befolkning dagtid',
      tot_anta: 'Totalt antall personer',
    },
  },
  propertyPrices: {
    types: {
      basicDistrict: 64,
      municipality: 46,
      county: 27,
    },
    numbers: [
      'eunder_500',
      'e500_1000',
      'e1000_1500',
      'e1500_2000',
      'e2000_2500',
      'e2500_3000',
      'e3000_4000',
      'e4000_5000',
      'e5000_6000',
      'e6000_over',
    ],
    text: ['antall_omsetninger'],
    textAvg: ['snittpris'],
    total: 'antall_omsetninger',
    labels: {
      eunder_500: '< 500.000',
      e500_1000: '500.000 - 1 mill',
      e1000_1500: '1 - 1,5 mill',
      e1500_2000: '1,5 - 2 mill',
      e2000_2500: '2 - 2,5 mill',
      e2500_3000: '2,5 - 3 mill',
      e3000_4000: '3 - 4 mill',
      e4000_5000: '4 - 5 mill',
      e5000_6000: '5 - 6 mill',
      e6000_over: '> 6 mill',
      antall_omsetninger: 'Antall omsetninger totalt',
      snittpris: 'Gjennomsnittlig eiendomspris',
    },
    units: {
      snittpris: ' kr',
    },
  },
  buildingsOwnerType: {
    types: {
      basicDistrict: 63,
      municipality: 45,
      county: 26,
    },
    numbers: ['selveier', 'eier_i_bor', 'leier'],
    text: ['selveier', 'tot_boforhold'],
    total: 'tot_boforhold',
    labels: {
      selveier: 'Selveiere',
      eier_i_bor: 'Borettslag',
      leier: 'Leier',
      tot_boforhold: 'Antall personer totalt',
    },
  },
  buildingsArea: {
    types: {
      basicDistrict: 62,
      municipality: 44,
      county: 25,
    },
    numbers: [
      'aru_50',
      'ar50_59',
      'ar60_79',
      'ar80_99',
      'ar100_119',
      'ar120_139',
      'ar140_159',
      'ar160_199',
      'ar200_249',
      'ar250_o',
      'ukjent',
    ],
    text: ['antallboenheterbolig'],
    textAvg: ['snittareal'],
    total: 'antallboenheterbolig',
    labels: {
      aru_50: '<= 50 kvm',
      ar50_59: '50 - 59 kvm',
      ar60_79: '60 - 79 kvm',
      ar80_99: '80 - 99 kvm',
      ar100_119: '100 - 119 kvm',
      ar120_139: '120 - 139 kvm',
      ar140_159: '140 - 159 kvm',
      ar160_199: '160 - 199 kvm',
      ar200_249: '200 - 249 kvm',
      ar250_o: '> 250 kvm',
      ukjent: 'Ukjent',
      antallboenheterbolig: 'Antall boligenheter',
      snittareal: 'Gjennomsnittlig boligareal',
    },
    units: {
      snittareal: 'm²',
    },
  },
  cabinOwners: {
    types: {
      basicDistrict: 67,
      municipality: 49,
      county: 30,
    },
    numbers: ['ant_fjell', 'ant_innland', 'ant_kyst'],
    text: ['tot_eiere', 'tot_andeler'],
    total: 'tot_eiere',
    labels: {
      ant_fjell: 'Fjellhytter',
      ant_innland: 'Innlandshytter',
      ant_kyst: 'Kysthytter',
      tot_eiere: 'Totalt antall hytteeiere',
      tot_andeler: 'Totalt antall hytter eid av hytteeierne',
    },
  },
  cabins: {
    types: {
      basicDistrict: 68,
      municipality: 50,
      county: 31,
    },
    numbers: ['ant_fjell', 'ant_innland', 'ant_kyst'],
    text: ['tot_hytter'],
    total: 'tot_hytter',
    labels: {
      ant_fjell: 'Fjellhytter',
      ant_innland: 'Innlandshytter',
      ant_kyst: 'Kysthytter',
      tot_hytter: 'Totalt antall hytter',
    },
  },
};

export function getDemographyBaseQuery(): DemographyQuery {
  return {
    where: '',
    text: '',
    objectIds: '',
    time: '',
    timeRelation: 'esriTimeRelationOverlaps',
    geometry: '',
    geometryType: 'esriGeometryEnvelope',
    inSR: '',
    spatialRel: 'esriSpatialRelIntersects',
    distance: '',
    units: 'esriSRUnit_Meter',
    relationParam: '',
    outFields: '',
    returnGeometry: true,
    returnTrueCurves: false,
    maxAllowableOffset: '',
    geometryPrecision: '',
    outSR: '',
    havingClause: '',
    returnIdsOnly: false,
    returnCountOnly: false,
    orderByFields: '',
    groupByFieldsForStatistics: '',
    outStatistics: '',
    returnZ: false,
    returnM: false,
    gdbVersion: '',
    historicMoment: '',
    returnDistinctValues: false,
    resultOffset: '',
    resultRecordCount: '',
    returnExtentOnly: false,
    sqlFormat: 'none',
    datumTransformation: '',
    parameterValues: '',
    rangeValues: '',
    quantizationParameters: '',
    featureEncoding: 'esriDefault',
    f: 'html',
  };
}

export async function queryDemography(
  type: number,
  query: DemographyQuery
): Promise<DemographyResult> {
  const data = query as unknown as Record<string, unknown>;

  data.token = await getGeodataToken();
  const keys = Object.keys(data);
  let url = `${process.env.VUE_APP_DEMOGRAPHY_ENDPOINT}/${encodeURIComponent(
    type
  )}/query?`;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; ++i) {
    if (!Object.prototype.hasOwnProperty.call(data, keys[i])) {
      // eslint-disable-next-line no-continue
      continue;
    }

    if (url.substring(url.length - 1) !== '?') {
      url += '&';
    }

    url += `${encodeURIComponent(keys[i])}=`;

    if (typeof data[keys[i]] === 'object') {
      url += encodeURIComponent(JSON.stringify(data[keys[i]]));
    } else {
      url += encodeURIComponent(String(data[keys[i]]));
    }
  }

  return fetch(url).then((response) => {
    if (!response.ok || response.status !== 200) {
      throw new Error(`Failed to fetch demography: ${response.status}`);
    }

    return response.json() as unknown as DemographyResult;
  });
}

export function filterDemography(
  demography: DemographyResult,
  type: DemographyDataField
): DemographyFiltered {
  const result: DemographyFiltered = {
    numbers: {},
    text: {},
    percent: {},
  };

  const keys = Object.keys(demography.features[0].attributes);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; ++i) {
    if (
      !Object.prototype.hasOwnProperty.call(
        demography.features[0].attributes,
        keys[i]
      )
    ) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const label = type.labels[keys[i]];

    if (!label) {
      Logger.warn(`${keys[i]} not in returned Demography data set`);
      // eslint-disable-next-line no-continue
      continue;
    }

    const value = Number(demography.features[0].attributes[keys[i]]);
    const total = Number(demography.features[0].attributes[type.total]);

    if (Number.isNaN(value)) {
      if (
        keys[i] !== 'grunnkretsnavn' &&
        keys[i] !== 'kommunenavn' &&
        keys[i] !== 'fylkesnavn'
      ) {
        Logger.warn(
          `"${label}" value is unexpected NaN: ${
            demography.features[0].attributes[keys[i]]
          } (${value})`
        );
      }

      // eslint-disable-next-line no-continue
      continue;
    }

    let unit = '';

    if (
      type.units &&
      Object.prototype.hasOwnProperty.call(type.units, keys[i])
    ) {
      unit = type.units[keys[i]];
    }

    if (type.numbers.indexOf(keys[i]) !== -1) {
      result.numbers[label] = NumberFormat.format(Math.round(value)) + unit;
      result.percent[label] = Math.round((value / total) * 100) || 0;
    }

    if (type.text.indexOf(keys[i]) !== -1) {
      result.text[label] = NumberFormat.format(Math.round(value)) + unit;
    }

    if (type.textAvg && type.textAvg.indexOf(keys[i]) !== -1) {
      result.text[label] = NumberFormat.format(Math.round(value)) + unit;
    }
  }

  return result;
}

export async function getDemographyFromExtent(
  type: number,
  fields: string[],
  geometry: Extent,
  outStatistics?: StatisticDefinition[]
): Promise<DemographyResult> {
  const query = getDemographyBaseQuery();

  query.f = 'pjson';
  query.returnGeometry = false;
  query.outFields = fields.join(',');
  query.geometry = geometry;

  if (outStatistics) {
    query.outStatistics = outStatistics;
  }

  return queryDemography(type, query);
}

export function getDemographyType(
  area: DemographyArea,
  type: DemographyDataField,
  geometry: Extent
): Promise<DemographyFiltered> {
  const fields = [
    ...new Set([...type.numbers, ...type.text, ...(type.textAvg ?? [])]),
  ];

  const outStatistics = fields.map((field) => {
    const statisticType = type.textAvg?.includes(field) ? 'avg' : 'sum';

    return {
      statisticType,
      onStatisticField: field,
      outStatisticFieldName: field,
    } as StatisticDefinition;
  });

  return getDemographyFromExtent(
    type.types[area],
    fields,
    geometry,
    outStatistics
  ).then((result) => filterDemography(result, type));
}

export function getDemographyAreaNames(
  geometry: Extent
): Promise<DemographyAreaNames> {
  return new Promise((resolve, reject) => {
    // TODO consider the need for this, maybe the area selector is not needed
    getDemographyFromExtent(
      5,
      ['grunnkretsnavn', 'kommunenavn', 'fylkesnavn'],
      geometry
    )
      .then((result) => {
        const attrs = result.features[0].attributes;
        const response: DemographyAreaNames = {
          basicDistrict: '',
          municipality: '',
          county: '',
        };

        if (
          Object.prototype.hasOwnProperty.call(attrs, 'grunnkretsnavn') &&
          typeof attrs.grunnkretsnavn === 'string' &&
          attrs.grunnkretsnavn.length
        ) {
          response.basicDistrict = String(attrs.grunnkretsnavn);
        }

        if (
          Object.prototype.hasOwnProperty.call(attrs, 'kommunenavn') &&
          typeof attrs.kommunenavn === 'string' &&
          attrs.kommunenavn.length
        ) {
          response.municipality = String(attrs.kommunenavn);
        }

        if (
          Object.prototype.hasOwnProperty.call(attrs, 'fylkesnavn') &&
          typeof attrs.fylkesnavn === 'string' &&
          attrs.fylkesnavn.length
        ) {
          response.county = String(attrs.fylkesnavn);
        }

        resolve(response);
      })
      .catch(reject);
  });
}
