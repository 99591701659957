import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]


import DownloadIcon from '@icons/regular/download-simple.svg';
import { GetAuthToken } from '@/data/xhr';


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadLink',
  props: {
  link: {
    type: String,
    required: true,
  },
  fileName: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const getAuthToken = async () => {
  return encodeURIComponent(await GetAuthToken());
};

const onDownload = () => {
  getAuthToken().then((authToken) => {
    const a = document.createElement('a');

    a.style.display = 'none';
    a.href = `${props.link}?authorization=${authToken}&download=true`;
    a.download = props.fileName;

    if (typeof a.download === 'undefined') {
      a.setAttribute('target', '_blank');
    }

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    class: "link-block",
    href: __props.link,
    onClick: _withModifiers(onDownload, ["prevent"])
  }, [
    _createTextVNode(_toDisplayString(__props.label) + " ", 1),
    _createVNode(_unref(DownloadIcon))
  ], 8, _hoisted_1))
}
}

})