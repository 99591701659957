
import { defineComponent, PropType } from 'vue';
import { createPopper, Instance, OptionsGeneric } from '@popperjs/core';
import type { StrictModifiers } from '@popperjs/core';

function getUniqueId(base = 'uid', maxAttempts = 10): string {
  let id = base;
  let elem = document.getElementById(id);
  let attempts = 0;

  while (elem !== null) {
    attempts += 1;

    if (attempts === maxAttempts) {
      throw new Error('Max unique attempts reached');
    }

    id += Math.floor(Math.random() * 10).toString();
    elem = document.getElementById(id);
  }

  return id;
}

export default defineComponent({
  name: 'Popover',
  props: {
    target: {
      type: String,
      required: true,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<Partial<OptionsGeneric<StrictModifiers>>> | null,
      default: null,
    },
    modifiers: {
      type: Object as PropType<StrictModifiers[]> | null,
      default: null,
    },
    toggleListeners: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showListeners: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    hideListeners: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const id = getUniqueId(`${props.target}Popover`);

    return {
      id: getUniqueId(`${props.target}Popover`),
      arrowId: getUniqueId(`${id}Arrow`),
    };
  },
  data() {
    return {
      tooltipDOM: null as HTMLDivElement | null,
      arrowDOM: null as HTMLDivElement | null,
      targetDOM: null as HTMLElement | null,
      popperInstance: null as Instance | null,
      configuredListeners: {} as Record<string, (() => void)[]>,
    };
  },
  mounted() {
    this.targetDOM = document.getElementById(this.target);
    const tooltipDOM = document.getElementById(this.id);

    if (this.targetDOM === null) {
      throw new Error(`Tooltip target DOM #${this.target} does not exist`);
    }

    if (tooltipDOM === null) {
      throw new Error(`Tooltip DOM #${this.id} does not exist`);
    } else {
      this.tooltipDOM = tooltipDOM as HTMLDivElement;
    }

    if (this.arrow) {
      const arrowDOM = document.getElementById(this.arrowId);

      if (arrowDOM !== null) {
        this.arrowDOM = arrowDOM as HTMLDivElement;
      }
    }

    let { options } = this;
    let { modifiers } = this;
    const hasModifiers = Object.prototype.hasOwnProperty.call(
      options,
      'modifiers'
    );

    if (options === null) {
      options = {};
    }

    if (hasModifiers && options.modifiers) {
      if (modifiers === null) {
        modifiers = options.modifiers;
      } else {
        modifiers = { ...options.modifiers, ...modifiers };
      }

      delete options.modifiers;
    }

    if (modifiers == null) {
      modifiers = [];
    }

    this.popperInstance = createPopper<StrictModifiers>(
      this.targetDOM,
      tooltipDOM,
      {
        ...options,
        modifiers: [
          ...modifiers,
          {
            name: 'eventListeners',
            enabled: false,
          },
        ],
      }
    );

    this.toggleListeners.forEach((event) => {
      this.addListener(event, (e?: Event) => {
        if (e) {
          e.stopPropagation();
        }

        this.toggle();
      });
    });

    this.showListeners.forEach((event) => {
      this.addListener(event, (e?: Event) => {
        if (e) {
          e.stopPropagation();
        }

        this.show();
      });
    });

    this.hideListeners.forEach((event) => {
      this.addListener(event, (e?: Event) => {
        if (e) {
          e.stopPropagation();
        }

        this.hide();
      });
    });
  },
  beforeUnmount() {
    if (this.targetDOM === null || !this.targetDOM) {
      return;
    }

    if (this.isVisible()) {
      this.hide();
    }

    Object.keys(this.configuredListeners).forEach((event) => {
      const hasEvent = Object.prototype.hasOwnProperty.call(
        this.configuredListeners,
        event
      );

      if (hasEvent) {
        this.configuredListeners[event].map((fn) =>
          this.targetDOM?.removeEventListener(event, fn)
        );
      }
    });
  },
  methods: {
    addListener(event: string, listener: (e?: Event) => void): void {
      if (this.targetDOM === null || !this.targetDOM) {
        return;
      }

      this.targetDOM.addEventListener(event, listener);

      const hasEvent = Object.prototype.hasOwnProperty.call(
        this.configuredListeners,
        event
      );

      if (!hasEvent) {
        this.configuredListeners[event] = [listener];
      } else {
        this.configuredListeners[event].push(listener);
      }
    },
    show(): void {
      this.tooltipDOM?.setAttribute('data-show', '');

      // Enable the event listeners
      this.popperInstance?.setOptions((options) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in options.modifiers) {
          if (Object.prototype.hasOwnProperty.call(options.modifiers, i)) {
            const idx = parseInt(i, 10);

            if (options.modifiers[idx].name === 'eventListeners') {
              // eslint-disable-next-line no-param-reassign
              options.modifiers[idx].enabled = true;
            }
          }
        }

        return options;
      });

      this.popperInstance?.update();

      document.addEventListener('click', this.clickOutsideListener);
      document.addEventListener('keydown', this.escKeyListener);
    },
    hide(): void {
      document.removeEventListener('keydown', this.escKeyListener);
      document.removeEventListener('click', this.clickOutsideListener);

      this.tooltipDOM?.removeAttribute('data-show');

      // Disable the event listeners
      this.popperInstance?.setOptions((options) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in options.modifiers) {
          if (Object.prototype.hasOwnProperty.call(options.modifiers, i)) {
            const idx = parseInt(i, 10);

            if (options.modifiers[idx].name === 'eventListeners') {
              // eslint-disable-next-line no-param-reassign
              options.modifiers[idx].enabled = false;
            }
          }
        }

        return options;
      });
    },
    toggle(): void {
      if (this.isVisible()) {
        this.hide();
      } else {
        this.show();
      }
    },
    isVisible(): boolean {
      if (this.tooltipDOM === null || !this.tooltipDOM) {
        return false;
      }

      return this.tooltipDOM.hasAttribute('data-show');
    },
    clickOutsideListener(e: MouseEvent): void {
      const withinPopover = this.tooltipDOM?.contains(e.target as HTMLElement);

      if (!withinPopover && this.isVisible()) {
        this.hide();
      }
    },
    escKeyListener(e: KeyboardEvent): void {
      if (e.key === 'Escape' && this.isVisible()) {
        e.stopPropagation();
        this.hide();
      }
    },
  },
});
