
import { defineComponent, PropType, ref, watch } from 'vue';
import { AuxBanner } from '@ambita/design-system';

import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import ArrowSquareOutIcon from '@icons/regular/arrow-square-out.svg';
import Property from '@/data/wrapper/Property';
import Heritage from '@/data/wrapper/Heritage';
import PropertySelection from '@/data/wrapper/PropertySelection';
import TextUtil from '@/utilities/TextUtil';
import Realty from '@/data/Realty';
import { useRoute } from 'vue-router';
import PanelBackButton from '@/components/PanelSystem/PanelBackButton.vue';
import PanelSpinner from '@/components/PanelSystem/PanelSpinner.vue';
import PanelGroup from '@/components/PanelSystem/PanelGroup.vue';

export default defineComponent({
  name: 'Heritage',
  components: {
    PanelBackButton,
    PanelRow,
    PanelColumn,
    ArrowSquareOutIcon,
    AuxBanner,
    PanelSpinner,
    PanelGroup,
  },
  props: {
    heritageKey: {
      type: String,
      required: true,
    },
    cadastreKey: {
      type: String,
      required: true,
    },
    property: {
      type: Object as PropType<Property> | null,
      default: null,
    },
    selectedProperties: {
      type: Object as PropType<PropertySelection>,
      default: null,
    },
  },
  setup(props) {
    const route = useRoute();
    const heritage = ref<Heritage | null>(null);
    const isLoading = ref(false);
    const errorMessage = ref<string | null>(null);

    const getHeritage = () => {
      if (route.params.heritageKey) {
        isLoading.value = true;
        errorMessage.value = null;
        Realty.getSingleHeritage(props.heritageKey)
          .then((result) => {
            heritage.value = result;
            isLoading.value = false;
          })
          .catch(() => {
            errorMessage.value = `Klarte ikke hente informasjon om bygning ${props.heritageKey}`;
          });
      }
    };

    watch(() => props.heritageKey, getHeritage, { immediate: true });

    return { heritage, isLoading, errorMessage };
  },
  computed: {
    heritageType(): string {
      if (this.heritage === null) {
        return '';
      }

      return new TextUtil(this.heritage.heritageType.text).uCaseFirst();
    },
  },
});
