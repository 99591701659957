import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_item = _resolveComponent("list-item")!
  const _component_list = _resolveComponent("list")!
  const _component_list_collapsible_block = _resolveComponent("list-collapsible-block")!

  return (_openBlock(), _createBlock(_component_list_collapsible_block, {
    label: "Seksjoner",
    count: _ctx.sections.length,
    loading: _ctx.loadStatus === 'loading',
    error: _ctx.loadStatus === 'error'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
            return (_openBlock(), _createBlock(_component_list_item, {
              title: section.key,
              link: `${_ctx.projectStore.getProjectRoute}/${section.key}`,
              key: section.key
            }, null, 8, ["title", "link"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["count", "loading", "error"]))
}