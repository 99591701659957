import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "value-estimate"
}
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "estimate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HjemlaLogo = _resolveComponent("HjemlaLogo")!
  const _component_CaretDownIcon = _resolveComponent("CaretDownIcon")!

  return (_ctx.estimate !== null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("header", null, [
          _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Verdiestimat", -1)),
          _createElementVNode("a", {
            href: _ctx.hjemlaUrl,
            target: "_blank"
          }, [
            _createVNode(_component_HjemlaLogo, {
              class: "logo",
              alt: "Hjemla logo"
            }),
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sr-only" }, "Hjemla.no", -1))
          ], 8, _hoisted_2)
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.estimateFormatted), 1),
        _createElementVNode("details", null, [
          _createElementVNode("summary", null, [
            _createVNode(_component_CaretDownIcon, { class: "caret" }),
            _cache[2] || (_cache[2] = _createTextVNode("Om verdiestimatet"))
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, [
            _createTextVNode(" Treffsikkerheten til verdiestimatet avhenger av hvor mye data som er tilgjengelig for boligen og for det aktuelle området. Noen områder har mer detaljert boliginformasjon tilgjengelig - for eksempel flere omsetninger, informasjon om størrelse på boligene, antall soverom osv. Generelt sett vil treffsikkerheten være høyere for boliger hvor man har mer data tilgjengelig. "),
            _createElementVNode("br"),
            _createElementVNode("a", {
              href: "https://support.hjemla.no/hc/no/sections/11314511508242-Verdiestimatet",
              target: "_blank"
            }, " Les mer om verdiestimatet på hjemla.no ")
          ], -1))
        ])
      ]))
    : _createCommentVNode("", true)
}