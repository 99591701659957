
import { defineComponent, PropType } from 'vue';
import DataLoader from '@/components/helper/DataLoader.vue';
import { AuxToggle } from '@ambita/design-system';
import VerticalBarChart from '@/components/Graphs/VerticalBarChart.vue';
import NumberText from '@/components/Graphs/NumberText.vue';
import {
  DemographyDataField,
  DemographyArea,
  getDemographyType,
} from '@/data/Demography';
import Extent from '@arcgis/core/geometry/Extent';
import Logger from '@/utilities/Logger';

export default defineComponent({
  name: 'Demography',
  components: {
    DataLoader,
    AuxToggle,
    VerticalBarChart,
    NumberText,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    geometry: {
      type: Object as PropType<Extent>,
      required: true,
    },
    area: {
      type: String as PropType<DemographyArea>,
      required: true,
    },
    type: {
      type: Object as PropType<DemographyDataField>,
      required: true,
    },
    hideGraph: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      displayNumbers: false,
      error: null as string | null,
      percentData: {} as Record<string, number>,
      textData: {} as Record<string, string>,
      numberData: {} as Record<string, string>,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    area(): void {
      this.load();
    },
    type(): void {
      this.load();
    },
    geometry(): void {
      this.load();
    },
  },
  methods: {
    load(): void {
      if (!this.geometry) {
        this.error = 'Point not loaded';
        this.loading = false;
        return;
      }

      this.loading = true;
      this.error = null;

      getDemographyType(this.area, this.type, this.geometry)
        .then((filtered) => {
          this.numberData = filtered.numbers;
          this.textData = filtered.text;
          this.percentData = filtered.percent;
        })
        .catch((e) => {
          const msg = Object.prototype.hasOwnProperty.call(e, 'message')
            ? e.message
            : String(e);

          Logger.error(
            `Failed to load demography type ${this.type.types.basicDistrict}: ${msg}`
          );
          this.error = 'Feil ved innlasting';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleMode(): void {
      this.displayNumbers = !this.displayNumbers;
    },
  },
});
