
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PanelRow',
  props: {
    columns: {
      type: String,
      required: false,
      default: '1',
    },
  },
});
