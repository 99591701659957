import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-testid"]
const _hoisted_2 = ["data-testid"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["data-testid"]

import { DataTestId } from '@/composables';
import IconCaretDown from '@icons/regular/caret-down.svg';
import { Ref, toRef } from 'vue';
import { useProjectStore } from '@/store/project';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectPicker',
  props: {
    dataTestid: {},
    dark: { type: Boolean, default: false }
  },
  setup(__props: any) {

const projectStore = useProjectStore();
const props = __props;

const { getTestId } = DataTestId(
  toRef(props, 'dataTestid') as Ref<string | undefined>
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": _ctx.dataTestid,
    class: _normalizeClass({ 'project-picker': true, dark: _ctx.dark })
  }, [
    (_unref(projectStore).project)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _cache[0] || (_cache[0] = _createTextVNode(" Valgt prosjekt: ")),
          _createElementVNode("span", {
            class: "project-name",
            "data-testid": _unref(getTestId)('anchor')
          }, _toDisplayString(_unref(projectStore).project?.Name), 9, _hoisted_2)
        ], 64))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, "Prosjektverktøy")),
    _createElementVNode("span", {
      "data-testid": _unref(getTestId)('caret'),
      class: "picker-menu",
      "aria-label": "Undermeny",
      tabindex: "0"
    }, [
      _createVNode(_unref(IconCaretDown), {
        width: "1em",
        height: "1em"
      })
    ], 8, _hoisted_4)
  ], 10, _hoisted_1))
}
}

})