
import { defineComponent, PropType } from 'vue';
import ArrowSquareOutIcon from '@icons/regular/arrow-square-out.svg';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import {
  GeonorgePlan,
  getMunicipalityPlans,
  getZoningPlans,
} from '@/data/Geonorge';
import Cadastre from '@/data/wrapper/Cadastre';
import GisUtil from '@/utilities/GisUtil';
import List from './Blocks/List.vue';
import ListCollapsibleBlock from './Blocks/ListCollapsibleBlock.vue';

export function mockOsloPlan(
  cadastre: Cadastre,
  type: string
): Promise<Array<GeonorgePlan>> {
  let odFeatures = [9];

  if (type === 'Kommuneplaner') {
    odFeatures = [9, 1, 15, 16, 17, 18, 82, 84, 85];
  }
  const [lng, lat] = cadastre.position.geometry.coordinates;

  const point = GisUtil.latLngToUtmPoint({
    lat,
    lng,
  });

  return Promise.resolve([
    {
      type: type.substring(0, type.length - 2),
      firstDigitalizationDate: null,
      updatedDate: null,
      link: `https://od2.pbe.oslo.kommune.no/kart/#${point[0]},${
        point[1]
      },${odFeatures.join(',')}`,
      municipalityNumber: '301',
      planID: null,
      planType: null,
      planStatus: null,
      planDecision: null,
      lawReference: null,
      planName: 'Se Oslo kommunes ressurs for eiendommen',
    },
  ]);
}

export default defineComponent({
  name: 'Plans',
  components: {
    ArrowSquareOutIcon,
    PanelRow,
    PanelColumn,
    List,
    ListCollapsibleBlock,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    cadastre: {
      type: Object as PropType<Cadastre>,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      loading: true,
      error: null as null | Error,
      plans: [] as Array<GeonorgePlan>,
    };
  },
  watch: {
    cadastre() {
      this.loadPlans();
    },
  },
  created() {
    this.loadPlans();
  },
  methods: {
    sanitizeLink(url: string): string {
      return url.replaceAll(/&amp;/gi, '&');
    },
    loadPlans(): void {
      if (!this.cadastre?.position?.geometry?.coordinates) {
        this.loading = false;
        return;
      }

      let promise: Promise<GeonorgePlan[]>;
      this.loading = true;

      const isOslo = this.cadastre.municipality?.municipalityNumber === 301;
      const [lng, lat] = this.cadastre.position.geometry.coordinates;

      if (isOslo) {
        promise = mockOsloPlan(this.cadastre, this.type);
      } else if (this.type === 'Kommuneplaner') {
        promise = getMunicipalityPlans(lat, lng);
      } else if (this.type === 'Reguleringsplaner') {
        promise = getZoningPlans(lat, lng);
      } else {
        this.loading = false;
        this.error = new Error(`Unsupported plan type ${this.type}`);
        return;
      }

      promise
        .then((plans) => {
          this.plans = plans;
        })
        .catch((e) => {
          this.plans = [];
          this.error = e;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
