
import { AuxBanner, AuxButton, AuxSpinner } from '@ambita/design-system';
import { defineComponent, inject, ref, watch, computed } from 'vue';

import { StoredCadastreKey, DefaultStoredCadastre } from '@/symbols';
import PanelRow from '@/components/PanelSystem/PanelRow.vue';
import PanelColumn from '@/components/PanelSystem/PanelColumn.vue';
import PanelSpinner from '@/components/PanelSystem/PanelSpinner.vue';
import BuildingsList from '@/components/PropertyDetailsPanel/PropertyDetails/BuildingsList.vue';
import OwnerList from '@/components/PropertyDetailsPanel/PropertyDetails/OwnerList.vue';
import HeritagesList from '@/components/PropertyDetailsPanel/PropertyDetails/HeritagesList.vue';
import SectionList from '@/components/PropertyDetailsPanel/PropertyDetails/SectionList.vue';
import SoilContaminations from '@/components/PropertyDetailsPanel/PropertyDetails/SoilContaminationList.vue';
import PlanList from '@/components/PropertyDetailsPanel/PropertyDetails/PlanList.vue';
import CaretRightIcon from '@icons/regular/caret-right.svg';
import Property from '@/data/wrapper/Property';
import StarIcon from '@icons/regular/star.svg';
import StarFilledIcon from '@icons/fill/star-fill.svg';
import TextUtil from '@/utilities/TextUtil';
import { LatLngExpression } from 'leaflet';
import { useProjectStore } from '@/store/project';
import { useMapStore } from '@/store/map';
import Realty from '@/data/Realty';
import { useRouter } from 'vue-router';
import PrinterIcon from '@icons/regular/printer.svg';
import ArrowSquareIcon from '@icons/regular/arrow-square-out.svg';
import CrosshairIcon from '@icons/regular/crosshair.svg';
import PanelBackButton from '@/components/PanelSystem/PanelBackButton.vue';
import ValueEstimate from '@/components/ValueEstimate/ValueEstimate.vue';
import PanelHr from '@/components/PanelSystem/PanelHr.vue';
import DocumentsList from '@/components/PropertyDetailsPanel/PropertyDetails/DocumentsList.vue';
import PanelTitle from '@/components/PanelSystem/PanelTitle.vue';
import PanelGroup from '@/components/PanelSystem/PanelGroup.vue';
import PanelYesNoLabel from '@/components/PanelSystem/PanelYesNoLabel.vue';
import { useOnboardingStore } from '@/store/onboarding';
import GisUtil from '@/utilities/GisUtil';

const NumberFormat = new Intl.NumberFormat(['nb-NO']);

export default defineComponent({
  name: 'PropertyDetails',
  components: {
    AuxBanner,
    AuxButton,
    AuxSpinner,
    ValueEstimate,
    PanelSpinner,
    PanelRow,
    PanelColumn,
    PanelBackButton,
    BuildingsList,
    OwnerList,
    HeritagesList,
    SectionList,
    SoilContaminations,
    PlanList,
    DocumentsList,
    CaretRightIcon,
    StarIcon,
    StarFilledIcon,
    PrinterIcon,
    CrosshairIcon,
    ArrowSquareIcon,
    PanelHr,
    PanelTitle,
    PanelGroup,
    PanelYesNoLabel,
  },
  inheritAttrs: false,
  props: {
    dataTestid: {
      type: String,
      required: false,
    },
  },
  setup() {
    const router = useRouter();
    const projectStore = useProjectStore();
    const onboardingStore = useOnboardingStore();
    const mapStore = useMapStore();
    const savingProperty = ref(false);
    const savePropertyError = ref(false);
    const showValueEstimate = ref(
      process.env.VUE_APP_SHOW_VALUE_ESTIMATE?.toLowerCase() === 'true'
    );
    const currentProperty = ref<null | Property>(null);
    const isLoading = ref(true);
    const errorMessage = ref<string | null>(null);
    const getProperty = () => {
      isLoading.value = true;
      const { cadastreKey } = router.currentRoute.value.params;

      if (typeof cadastreKey === 'string') {
        Realty.getProperty(cadastreKey)
          .then((result) => {
            currentProperty.value = new Property(result);
            projectStore.viewProperty(result.cadastre.key, result);
          })
          .catch(() => {
            errorMessage.value = `Fant ikke ${cadastreKey}`;
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    const isSection = () => {
      if (typeof router.currentRoute.value.params.cadastreKey === 'string') {
        return (
          router.currentRoute.value.params.cadastreKey.split('-').pop() !== '0'
        );
      }
      return false;
    };

    const getMainCadastre = () => {
      if (typeof router.currentRoute.value.params.cadastreKey === 'string') {
        const [knr, gnr, bnr, fnr] =
          router.currentRoute.value.params.cadastreKey.split('-');

        return `${knr}-${gnr}-${bnr}-${fnr}-0`;
      }
      return null;
    };

    const addressText = computed(() => {
      let text = '';
      const address = currentProperty.value?.getAddress() || '';

      if (!address) {
        return 'Ingen adresse';
      }

      if (address.isMatrikkelAddress()) {
        text = 'Matrikkeladresse: ';
      }

      return text + address.addressText();
    });

    const getLatLng = computed(() => {
      let lat: number | undefined;
      let lng: number | undefined;
      lat = currentProperty.value?.cadastre?.position?.geometry?.coordinates[0];

      lng = currentProperty.value?.cadastre?.position?.geometry?.coordinates[1];

      const boundingBox = currentProperty.value?.cadastre?.geojson.bbox;
      if ((!lat || !lng) && boundingBox) {
        // if polygon does not exist, fall back to deprecated boundingbox.
        const center = GisUtil.utmBboxToLatLngBounds(boundingBox).getCenter();
        lat = center.lng;
        lng = center.lat;
      }

      return { lat, lng };
    });

    const displayPropertyInMap = () => {
      const { lat, lng } = getLatLng.value;

      if (lat && lng) {
        mapStore.setMapCenterLatLng(lat, lng);
      }
    };

    const getStreetViewLink = computed(() => {
      const { lat, lng } = getLatLng.value;

      return `http://maps.google.com/maps?q=&layer=c&cbll=${lng},${lat}`;
    });

    const goToStreetView = () => {
      window.open(getStreetViewLink.value);
    };

    const conveyanceType = computed(() => {
      if (
        currentProperty.value?.latestConveyance === null ||
        !currentProperty.value?.latestConveyance ||
        currentProperty.value?.latestConveyance.conveyanceType === null ||
        !currentProperty.value?.latestConveyance.conveyanceType
      ) {
        return '';
      }

      return new TextUtil(
        currentProperty.value?.latestConveyance.conveyanceType.text
      ).uCaseFirst();
    });

    watch(() => router.currentRoute.value.params.cadastreKey, getProperty, {
      immediate: true,
    });

    onboardingStore.startTour('reportGenerator', 1500);
    onboardingStore.startTour('propertyPanel', 1500);

    return {
      router,
      getStreetViewLink,
      getMainCadastre,
      goToStreetView,
      displayPropertyInMap,
      errorMessage,
      isLoading,
      addressText,
      conveyanceType,
      savingProperty,
      savePropertyError,
      projectStore,
      mapStore,
      currentProperty,
      NumberFormat,
      isSection,
      showValueEstimate,
      cadastreStage: inject(StoredCadastreKey, DefaultStoredCadastre),
    };
  },

  unmounted() {
    if (
      !this.$route.params.soilContaminationKey &&
      !this.$route.params.heritageKey &&
      !this.$route.params.buildingKey
    ) {
      this.clearCurrentProperty();
    }
  },

  emits: [
    'retrievePropertyByKeyAndShow',
    'moveMarkerToPosition',
    'removeMarker',
    'clearCurrentProperty',
    'setCurrentPolygon',
    'showRegistryModal',
    'closeRegistryModal',
    'closeProductsModal',
    'generateReport',
    'showDemography',
  ],
  methods: {
    getTestid(id: string): string | undefined {
      if (this.dataTestid) {
        return `${this.dataTestid}-${id}`;
      }

      return undefined;
    },

    moveMarkerToPosition(latlng: LatLngExpression): void {
      this.$emit('moveMarkerToPosition', latlng);
    },

    removeMarker(): void {
      this.$emit('removeMarker');
    },

    clearCurrentProperty(): void {
      this.projectStore.setCurrentProperty(null);
    },

    deleteProperty(): void {
      if (this.currentProperty) {
        this.projectStore.removeProperty(this.currentProperty.cadastre.key);
      }
    },

    saveProperty(): void {
      if (this.currentProperty) {
        this.projectStore.addProperty(
          this.currentProperty.cadastre.key,
          this.currentProperty,
          true
        );
      }
    },
  },
  computed: {
    isSaved(): boolean {
      return (
        this.projectStore.selectedCadastres.filter((cadastre) => {
          return cadastre === String(this.$route.params.cadastreKey);
        }).length > 0
      );
    },
  },
});
