<template>
  <ul>
    <slot />
  </ul>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
}
</style>
